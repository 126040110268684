import React, { useState } from 'react';
import Providers from './context';
import AppRoutes from './Routes';

function App() {
  return (
    <Providers>
      <AppRoutes />
    </Providers>
  );
}

export default App;