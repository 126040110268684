import { useState } from "react";
import { MagnifyingGlassMinusIcon } from "@heroicons/react/24/solid";
import PropTypes from 'prop-types';

ImagePreview.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  canZoom: PropTypes.bool,
}

export default function ImagePreview({ file, url, canZoom = false }) {
  const [zoom, setZoom] = useState(1);
  const [transformOrigin, setTransformOrigin] = useState("center center");

  const handleImageClick = (e) => {
    if (canZoom) {
      const { offsetX, offsetY, target } = e.nativeEvent;
      const { width, height } = target;
  
      const xPercent = (offsetX / width) * 100;
      const yPercent = (offsetY / height) * 100;
  
      setTransformOrigin(`${xPercent}% ${yPercent}%`);
      setZoom((prevZoom) => prevZoom + 0.1);
    }
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1));
  };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <img
        src={url || URL.createObjectURL(file)}
        alt={file.name}
        className={`mt-4 rounded-lg border border-gray-300 ${canZoom && 'cursor-zoom-in'}`}
        style={{
          maxWidth: "100%",
          transform: `scale(${zoom})`,
          transformOrigin,
        }}
        onClick={handleImageClick}
      />
      {canZoom && <div className="absolute top-0 right-0 mt-2">
        <MagnifyingGlassMinusIcon
          className="h-6 w-6 text-blue-500 cursor-pointer"
          onClick={handleZoomOut}
        />
      </div>}
    </div>
  );
}
