import AuthProvider from "./Auth";
import LoaderProvider from "./Loader";
import ToasterProvider from "./Toaster";

export default function Providers({ children }) {
  return (
    <ToasterProvider>
      <LoaderProvider>
        <AuthProvider>{children}</AuthProvider>
      </LoaderProvider>
    </ToasterProvider>
  );
}
