import { Viewer, Worker } from "@react-pdf-viewer/core";
import { GlobalWorkerOptions } from "pdfjs-dist";
import PropTypes from "prop-types";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

PdfPreview.propTypes = {
  fileUrl: PropTypes.string,
  onPageLoad: PropTypes.func,
};

export default function PdfPreview({
  fileUrl,
  onPageLoad = ({ items: _items, styles: _styles }) => {},
}) {
  const handlePageChange = ({ currentPage: currentPageIndex, doc }) =>
    doc.getPage(currentPageIndex + 1).then((page) => {
      return page.getTextContent().then((textContent) => {
        // need to set timeout, otherwise query selector all will return empty for some reason
        setTimeout(() => {
          onPageLoad(textContent, page.view, currentPageIndex);
        }, 100);
      });
    });
    

  return (
    <Worker
      workerUrl="/pdf.worker.min.js"
    >
      <Viewer fileUrl={fileUrl} onPageChange={handlePageChange} />
    </Worker>
  );
}
