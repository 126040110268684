import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../App.css';  
import WizardSteps from './WizardSteps';
import UploadAndProcessStep from './UploadAndProcessStep';
import { DOCUMENT_TYPE_ENUM, DOCUMENT_TYPE_GENERATED, DOCUMENT_TYPE_UPLOADED } from '../../constants';
import ValidateDataStepV2 from './ValidateDataStepV2';
import { API_UPLOAD_FILE, API_URL_STANDARD_ACCOUNTS } from '../../constants/url';
import ResultStepV2 from './ResultStepV2';
import { FORMULAS_MAP, VALIDATION_FORMULA_MAP } from '../../constants/formula';
import calculateFormulaFromData from '../../utils/formula/calculate_formula_from_data';
import { useLoader } from '../../context/Loader';

const FinancialSpreading = () => {
  const { setLoading } = useLoader()

  const [currentStep, setCurrentStep] = useState(1);
  const [file, setFile] = useState(null);
  const [tpIsStandardAccountOptions, setTpIsStandardAccountOptions] = useState([]);
  const [tpCfStandardAccountOptions, setTpCfStandardAccountOptions] = useState([]);
  const [tpBsStandardAccountOptions, setTpBsStandardAccountOptions] = useState([]);
  const [financialData, setFinancialData] = useState([]);
  const [financialMetaData, setFinancialMetaData] = useState({});

  useEffect(() => {
    const fetchingIsTpStandardAccount = async () => {
      try {
        const res = await axios.get(`${API_URL_STANDARD_ACCOUNTS}/tp-is-standard-account-options`, {
          withCredentials: true,
        });
        setTpIsStandardAccountOptions(res.data.response.sort(function (a, b) {
          var accountCodeA = a.account_code;
          var accountCodeB = b.account_code;
            return (accountCodeA < accountCodeB) ? -1 :(accountCodeA > accountCodeB) ? 1 : 0;
          }
        ));
       
      } catch (error) {
        console.error('Error fetching Income Statement TP Standard Account options:', error);
      }
    };

    const fetchingBsTpStandardAccount = async () => {
      try {
        const res = await axios.get(`${API_URL_STANDARD_ACCOUNTS}/tp-bs-standard-account-options`, {
          withCredentials: true,
        });
        setTpBsStandardAccountOptions(res.data.response.sort(function (a, b) {
          var accountCodeA = a.account_code;
          var accountCodeB = b.account_code;
          return (accountCodeA < accountCodeB) ? -1 :(accountCodeA > accountCodeB) ? 1 : 0;
          }
        ));
       
      } catch (error) {
        console.error('Error fetching Balance Sheet TP Standard Account options:', error);
      }
    };

    const fetchingCfTpStandardAccount = async () => {
      try {
        const res = await axios.get(`${API_URL_STANDARD_ACCOUNTS}/tp-cf-standard-account-options`, {
          withCredentials: true,
        });
        setTpCfStandardAccountOptions(res.data.response.sort(function (a, b) {
          var accountCodeA = a.account_code;
          var accountCodeB = b.account_code;
            return (accountCodeA < accountCodeB) ? -1 :(accountCodeA > accountCodeB) ? 1 : 0;
          }
        ));
       
      } catch (error) {
        console.error('Error fetching Cash Flow Statement TP Standard Account options:', error);
      }
    };

    fetchingIsTpStandardAccount();
    fetchingBsTpStandardAccount();
    fetchingCfTpStandardAccount();
  }, []);

  const tpStandardAccountOptionsMap = {
    [DOCUMENT_TYPE_ENUM.INCOME_STATEMENT]: tpIsStandardAccountOptions,
    [DOCUMENT_TYPE_ENUM.BALANCE_SHEET]: tpBsStandardAccountOptions,
    [DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT]: tpCfStandardAccountOptions,
  };

  const handleUploadProcess = async (file, uploaded_document_type) => {
    setLoading(true); // Start loading

    try {
      const formData = new FormData();
      formData.append('file', file);  
      if (uploaded_document_type) {
        formData.append("document_type", uploaded_document_type);
      }

      const res = await axios.post(API_UPLOAD_FILE, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      });

      const { metadata, document_types, data } = res.data.response.reduce(({ metadata: currentMetadata, data: currentData, document_types }, {document_type, data, fiscal_period_order, page_number }) => {
        const newDocumentTypes = [...document_types, document_type]
        const newMetadata = {
          ...currentMetadata, 
          [document_type]: {
            file,
            url: URL.createObjectURL(file),
            fiscal_period_order,
            page_number,
          },
        }


        const processedData = data.map((currentData) => ({
          ...currentData,
          document_type,
        }))

        const newData = [...currentData, ...processedData]

        return {
          metadata: newMetadata,
          document_types: newDocumentTypes,
          data: newData,
        }

      }, {
        metadata: {},
        data: [],
        document_types: []
      })

      setFinancialMetaData((prevData) => ({
        ...prevData,
        ...metadata,
      }));
      setFinancialData((currentData) => {
        const documentTypesMap = document_types.reduce((finalMap, documentType) => {
          return {
            ...finalMap,
            [documentType]: true,
          }
        }, {})
        const filteredData = currentData.filter(({ document_type: dataDocumentType }) => !documentTypesMap[dataDocumentType] )

        data.sort((a, b) => a.row_number - b.row_number);

        return [
          ...filteredData,
          ...data,
        ];
      });
    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setLoading(false); 
  }
  };

  const handleNext = async () => {
    if (currentStep === 1) {
      await handleUploadProcess(file).then(() => setFile(null)); // Process and move to next step
    }

    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };


  const handleFinish = () => {
    setCurrentStep(1);
    setFile(null);
  };

  const calculateUploadedDocumentsFormula = () => {
    const calculatedDocumentTypesArr = DOCUMENT_TYPE_UPLOADED.filter(({ key }) => financialMetaData[key])
    const calculatedFormulas = calculatedDocumentTypesArr.reduce((fin, { key, formula_type = key }) => {
    const currFormula = FORMULAS_MAP[formula_type].map((data) => ({
      ...data,
      document_type: formula_type,
    }))
    return [...fin, ...currFormula]
  }, [])

  const baseCalculateMetadata = calculatedDocumentTypesArr.reduce((existingMetadata, { key, formula_type = key }) => {
    return {
      ...existingMetadata,
      [formula_type]: true
    }
  }, {
    isOldestYear: false,

    // TODO: replace this with user-edited number of months once possible
    numberOfMonths: 12,
  })

  const result = calculateFormulaFromData(financialData, calculatedFormulas, baseCalculateMetadata)

  result.sort((a, b) =>
      a.account_code.localeCompare(b.account_code, undefined, { numeric: true })
    );
    return result;
  };
  const calculatedUploadedDocuments = calculateUploadedDocumentsFormula()

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <UploadAndProcessStep
            onFileSelect={setFile}
            // onUpload={handleUploadProcess}
            // loading={isLoading}
            file={file}
          />
        );
      case 2:
        return (
            <ValidateDataStepV2
              onUpload={handleUploadProcess}
              tpStandardAccountOptionsMap={tpStandardAccountOptionsMap}
              setFinancialData={setFinancialData}
              data={financialData}
              metaData={financialMetaData}
              calculatedData={calculatedUploadedDocuments}
            />
        );
      case 3:{
        const calculatedUploadedDocumentTypes = DOCUMENT_TYPE_UPLOADED.reduce((currentCalculatedDocuments, currentDocumentType) => {
          const { key } = currentDocumentType
          if (financialMetaData[key]) {
            return {...currentCalculatedDocuments, [key]: currentDocumentType }
          }

          return currentCalculatedDocuments
        }, {})

        const calculatedGeneratedDocumentTypes = DOCUMENT_TYPE_GENERATED.reduce((currentCalculatedDocuments, currentDocumentType) => {
          const { key } = currentDocumentType
          if (calculatedUploadedDocumentTypes[key]) {
            return currentCalculatedDocuments
          }

          return { ...currentCalculatedDocuments, [key]: currentDocumentType }
        }, {})

        const shownDocumentTypes = {
          ...calculatedUploadedDocumentTypes,
          ...calculatedGeneratedDocumentTypes,
        };

        const shownDocumentTypesArr = Object.values(shownDocumentTypes);

        const calculatedDocumentTypesArr = Object.values(
          calculatedGeneratedDocumentTypes
        );

        const baseCalculateMetadata = shownDocumentTypesArr.reduce(
          (existingMetadata, { key, formula_type = key }) => {
            return {
              ...existingMetadata,
              [formula_type]: true,
            };
          },
          {
            isOldestYear: false,

            // TODO: replace this with user-edited number of months once possible
            numberOfMonths: 12,
          }
        );

        const calculateGeneratedDocumentsFormula = () => {
          const calculatedFormulas = calculatedDocumentTypesArr.reduce(
            (fin, { key, formula_type = key }) => {
              const currFormula = FORMULAS_MAP[formula_type].map((data) => ({
                ...data,
                document_type: formula_type,
              }));
              return [...fin, ...currFormula];
            },
            []
          );

          const aggregatedFinancialData = {};

          [...financialData, ...calculatedUploadedDocuments].forEach((item) => {
            const key = item.account_code; // You can change this to 'tp_standard_account_name' if needed
            if (!aggregatedFinancialData[key]) {
              aggregatedFinancialData[key] = {
                ...item,
                amount: { ...item.amount },
              };
              return;
            }

            Object.keys(item.amount).forEach((year) => {
              aggregatedFinancialData[key].amount[year] =
                (aggregatedFinancialData[key].amount[year] || 0) +
                item.amount[year];
            });
          });

          const aggregatedFinancialAndUploadedData = Object.values(aggregatedFinancialData)

          const calculatedGeneratedData = calculateFormulaFromData(
            aggregatedFinancialAndUploadedData,
            calculatedFormulas,
            baseCalculateMetadata
          );

          const allData = [
            ...aggregatedFinancialAndUploadedData,
            ...calculatedGeneratedData,
          ];

          allData.sort((a, b) =>
            a.account_code.localeCompare(b.account_code, undefined, {
              numeric: true,
            })
          );
          return allData;
        };

        const combinedDocuments = calculateGeneratedDocumentsFormula();

        return (
          <ResultStepV2
            reformattedData={combinedDocuments}
            metadata={financialMetaData}
            tabs={shownDocumentTypesArr}
          />
        )};
      default:
        return null;
    }
  };

  const isNextButtonDisabled = (() => {
    if (currentStep === 2) {
      if (DOCUMENT_TYPE_UPLOADED.filter(({ type }) => type === 'required').some(({ key }) => !financialMetaData[key])) {
        return true
      }
 
      const isInvalidData = Object.values(VALIDATION_FORMULA_MAP).some((data) => {
        const { validation } = data
        if (!validation) {
          return false
        }

        const { formula, equal } = validation

        const validatedData = calculateFormulaFromData(calculatedUploadedDocuments, [formula])

        const isInvalidData = validatedData.some(({ amount }) => Object.values(amount).some((value) => value !== equal))

        return isInvalidData
      })

      return isInvalidData
    }

    return false
  })()

  return (
    <div className="p-8 bg-gray-50">
      <div className="wizard-container p-6 bg-white rounded-lg shadow-lg">
        <WizardSteps currentStep={currentStep} />
        <div className="wizard-content mt-6">
          {renderStepContent()}
        </div>
        <div className="wizard-actions flex justify-end mt-6">
          {currentStep > 1 && (
            <button className="previous-button bg-gray-200 text-gray-800 py-2 px-4 rounded mr-2" onClick={handlePrevious}>Previous</button>
          )}
          {currentStep < 3 && currentStep !== 3 && (
            <button className={`next-button bg-blue-500 text-white py-2 px-4 rounded ${isNextButtonDisabled && 'bg-gray-300 text-gray-500 cursor-not-allowed'}`} disabled={isNextButtonDisabled} onClick={handleNext}>Next</button>
          )}
          {currentStep === 3 && (
            <button className="finish-button bg-blue-500 text-white py-2 px-4 rounded" onClick={handleFinish}>Finish</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinancialSpreading;
