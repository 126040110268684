import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import backIcon from "../../assets/icons/icon_back.svg";
import CommonTable from "../../components/CommonTable";
import Preview from "../../components/Preview";
import Tabs from "../../components/Tabs";
import { useApplicationContext } from "../../context/Application";
import { mockData } from "./mock_data";

export default function FinancialSpreadingUploadValidate() {
  const { metadata } = useApplicationContext();
  return (
    <div
      className={`w-full max-w-default px-16 relative min-h-[calc(100vh-70px)] flex flex-col`}
    >
      <div className="py-[40px] flex-col gap-[16px]">
        <div className="pb-[16px] flex justify-between">
          <div className="flex gap-[16px] flex-1 items-center">
            <img src={backIcon} alt="back-button" className="cursor-pointer" />
            <span className="text-gradient-aurora-blue text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
              Spreading Information
            </span>
          </div>
          <div className="flex gap-[20px]">
            <button className="w-[100px] !h-[48px] !py-[12px] !px-[20px] default border-[1px] border-neutral-medium font-[600] leading-[24px] tracking-[-0.48px] text-[16px]">
              Previous
            </button>
            <button className="w-[100px] !h-[48px] !py-[12px] !px-[20px] primary font-[600] leading-[24px] tracking-[-0.48px] text-[16px] text-white p-[12px]">
              Next
            </button>
          </div>
        </div>
        <ul className="list-disc pl-[26px] text-[18px] leading-[28px] tracking-[-0.54px] font-[500] max-w-[1187px] w-full">
          <li>
            Review Documents: Compare the original financial statements on the
            left with the mapped accounts and figures on the right.
          </li>
          <li>
            Verify and Edit: Confirm accuracy of mappings and figures. For
            corrections, click the column, select the appropriate account or
            adjust numbers as needed. After verifying all details, click “Next”
            to proceed
          </li>
        </ul>
      </div>

      <div className="flex flex-col items-center gap-[32px] w-full">
        <div className="max-w-[1792px] w-full flex flex-col gap-[24px]">
          <Tabs />
          <PanelGroup direction="horizontal" className="gap-[24px]">
            <Panel
              defaultSizePercentage={33}
              className="flex flex-col gap-[24px] min-w-[430px]"
            >
              <span className="text-[24px] font-[700] leading-[32px] tracking-[-0.72px]">
                Original Document
              </span>
              <div className="bg-white p-[24px] border-[1px] border-solid rounded-[20px] flex flex-col w-full">
                <Preview file={metadata.INCOME_STATEMENT.file} />
              </div>
            </Panel>
            <PanelResizeHandle className="relative">
              <div className="w-[8px] h-[320px] rounded-[1000px] bg-black-alpha-400 absolute top-[300px]" />
            </PanelResizeHandle>
            <Panel
              defaultSizePercentage={60}
              className="flex flex-col gap-[24px] flex-1 overflow-x-auto"
            >
              <span className="text-[24px] font-[700] leading-[32px] tracking-[-0.72px]">
                Financial Mapping
              </span>
              <CommonTable
                rowsData={mockData}
                label="Revenue & Operating Expenses"
              />
              <CommonTable
                rowsData={mockData}
                label="Interest and Non-Operating Income (Expense)"
              />
            </Panel>
          </PanelGroup>
        </div>
      </div>
    </div>
  );
}
