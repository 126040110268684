import { Link } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import auroraLogo from "../../assets/images/aurora_logo.svg";
import trustplusLogo from "../../assets/images/trustplus_logo.svg";
import Dropdowns from "../../components/Dropdowns";
import Textarea from "../../components/Textarea";
import TextInput from "../../components/TextInput";

export default function ContactUs({ fromErrorPage }) {
  return (
    <div className="w-full max-w-default px-16 pb-16 relative min-h-[100vh] flex flex-col">
      {fromErrorPage ? (
        <img src={trustplusLogo} className="w-[215px] h-[32px] mt-[52px]" />
      ) : (
        <div className="py-[40px] flex gap-[16px]">
          <img src={backIcon} alt="back-button" className="cursor-pointer" />
          <span className="text-gradient-aurora-blue  text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
            Contact Us
          </span>
        </div>
      )}
      <div className="flex flex-col justify-center items-center gap-[52px] w-full">
        {fromErrorPage && (
          <img
            src={auroraLogo}
            className="w-[300x] h-[83px] pb-24px mt-[48px]"
          />
        )}
        <div className="w-full max-w-[582px] p-[32px] rounded-[20px] bg-white flex flex-col gap-[24px]">
          {fromErrorPage && (
            <div>
              <span className="text-gradient-aurora-blue text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
                Contact Us
              </span>
            </div>
          )}
          <TextInput label="User Name" placeholder="Enter user name" />
          <TextInput label="User Email" placeholder="Enter user email" />
          <Dropdowns
            label="Subject Category"
            data={[
              {
                label: "Technical support",
              },
              {
                label: "Feedback and Suggestions",
              },
            ]}
            placeholder="Select from categories"
          />
          <TextInput label="Subject" placeholder="How can we help you today?" />
          <Textarea
            label="Your Message"
            placeholder="Feel free to explain in detail"
            className="flex flex-col"
            rows="4"
          />
          <button type="submit" className="primary w-full mt-[20px]">
            Submit
          </button>
          {fromErrorPage && (
            <Link>
              <button className="w-full text-[16px] leading-[24px] tracking-[-0.48px] font-[600] text-neutral-strong">
                Back to Homepage
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
