import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import {
  EDIT_COMPANY_FINANCIAL_SPREADING,
  MANAGE_ACCOUNTS,
  URL_APPLICATION_CREATE,
  URL_APPLICATION_VALIDATE,
  URL_CHANGE_PASSWORD,
  URL_CONTACT_US,
  URL_CONTACT_US_ERROR,
  URL_DASHBOARD,
  URL_FINANCIAL_SPREADING,
  URL_FORGOT_PASSWORD,
  URL_LOGIN,
  URL_RESET_PASSWORD,
  URL_USER_PROFILE,
  VIEW_CLIENT,
} from "./constants/url";
import ApplicationProvider from "./context/Application";
import ContactUs from "./pages/ContactUs";
import DashboardPage from "./pages/Dashboard";
import EditCompanyFinancialSpreading from "./pages/EditCompanyFinancialSpreading";
import FinancialSpreading from "./pages/FinancialSpreading/FinancialSpreading";
import FinancialSpreadingUploadPage from "./pages/FinancialSpreadingUpload";
import FinancialSpreadingUploadValidate from "./pages/FinancialSpreadingUpload/FinancialSpreadingUploadValidate";
import ForgotPassword from "./pages/ForgotPassword";
import Login from "./pages/Login";
import ManageAccounts from "./pages/ManageAccounts";
import Profile from "./pages/Profile";
import ProfileChangePassword from "./pages/Profile/ChangePassword";
import ResetPassword from "./pages/ResetPassword";
import ViewClient from "./pages/ViewClient";

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        {/* TODO: separate error pages, move not found to * route, 500 error to error boundary */}
        {/* <Route path={URL_ERROR_STATUS} element={<Error />} /> */}
        <Route exact path={URL_CONTACT_US} element={<ContactUs />} />
        <Route
          exact
          path={URL_CONTACT_US_ERROR}
          element={<ContactUs fromErrorPage={true} />}
        />
        <Route path={URL_LOGIN} element={<Login />} />
        <Route path={URL_FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={URL_RESET_PASSWORD} element={<ResetPassword />} />
        <Route element={<AuthenticatedRoute />}>
          <Route exact path={URL_DASHBOARD} element={<DashboardPage />} />
          <Route exact path={URL_USER_PROFILE} element={<Profile />} />
          <Route exact path={MANAGE_ACCOUNTS} element={<ManageAccounts />} />
          <Route exact path={VIEW_CLIENT} element={<ViewClient />} />
          <Route
            exact
            path={EDIT_COMPANY_FINANCIAL_SPREADING}
            element={<EditCompanyFinancialSpreading />}
          />
          <Route
            exact
            path={URL_CHANGE_PASSWORD}
            element={<ProfileChangePassword />}
          />
          <Route
            exact
            path={URL_FINANCIAL_SPREADING}
            element={<FinancialSpreading />}
          />
          <Route element={<ApplicationProvider />}>
            <Route
              exact
              path={URL_APPLICATION_CREATE}
              element={<FinancialSpreadingUploadPage />}
            />
            <Route
              exact
              path={URL_APPLICATION_VALIDATE}
              element={<FinancialSpreadingUploadValidate />}
            />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}
