import trusplus from "../../assets/images/trustplus_logo.svg";
import PendingApplicationsMenu from "./PendingApplicationsMenu";
import ProfileMenu from "./ProfileMenu";

export default function Header() {
  return (
    <div className="flex items-center justify-between h-header border-b px-16 w-full">
      <img src={trusplus} className="w-[176px] h-auto" alt="Aurora Plus" />
      <div className="flex items-center">
        <PendingApplicationsMenu />
        <ProfileMenu />
      </div>
    </div>
  );
}
