import React, { useRef, useState } from "react";
import {
  useDownloadExcel,
} from "react-export-table-to-excel";
import formatCurrency from "../../utils/currency/formatCurrency";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";

const ResultStepV2 = ({ reformattedData, metadata, tabs }) => {
  const tableRef = useRef(null);

  const [activeTab, setActiveTab] = useState(
    () => tabs[0].key
  );

  const filteredData = reformattedData.filter(
    ({ document_type }) => document_type === activeTab
  );

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Financial_Statement',
    sheet: activeTab,
  });

  const { currency, unit } = metadata;

  const { latest_fiscal_period } = metadata[activeTab] || {}

  const years = latest_fiscal_period || Array.from(
    new Set(filteredData.flatMap((item) => Object.keys(item.amount)))
  ).sort()

  const defaultFormat = (amount = 0) => formatCurrency(amount, currency)

  return (
    <div className="confirmation-step">
      <h2 className="text-xl font-semibold mb-4">Step 3: Mapping Result</h2>
      <p>Your data has been successfully mapped and below is the result:</p>

      <div className="table-container mt-4 p-2 border border-gray-300 rounded-lg">
        <div>
          <div>
            <h2 className="text-medium font-semibold text-red-500 dark:text-white">
              Instructions:
            </h2>
            <ol className="text-medium text-red-500 list-decimal list-inside">
              <li>
                View Financial Data: Review the table below which displays your
                financial statement in the predefined Aurora format.
              </li>
              <li>
                Download Data: To export the data to Excel, click the “Download
                Financial Statement” button.
              </li>
              <li>
                Complete or Modify: After completing your analysis, click
                “Finish” to conclude the session. If you need to make further
                changes, click the “Previous” button to return to earlier steps.
              </li>
            </ol>
          </div>

          <div className="flex justify-end">
            <button
              onClick={onDownload}
              className="bg-blue-500 text-white py-2 px-4 rounded mt-4"
            >
              Download Financial Statement
            </button>

            {/* <button onClick={downloadFinancialStatement} className="bg-blue-500 text-white py-2 px-4 rounded mt-4">Download Financial Statement 2</button> */}
          </div>
        </div>

        {unit ? (
          <div className="text-sm text-left font-semibold text-grey-500 capitalize">
            Unit: {unit}
          </div>
        ) : (
          <></>
        )}

        {currency ? (
          <div className="text-sm text-left font-semibold text-grey-500 capitalize">
            Currency: {currency}
          </div>
        ) : (
          <></>
        )}

        <div className="flex border-b mb-4">
          {tabs.map(({ key, formula_type = key, text }, index) => {
            return (
              <button
                key={index}
                className={`py-2 px-4 focus:outline-none ${
                  activeTab === formula_type
                    ? "border-b-2 border-blue-500 font-semibold"
                    : "text-gray-500"
                }`}
                onClick={() =>
                  activeTab !== formula_type && setActiveTab(formula_type)
                }
              >
                {text}
              </button>
            );
          })}
        </div>

        <table className="min-w-full bg-white border-collapse" ref={tableRef}>
          <thead>
            <tr align="center">
              <th className="border px-4 py-2">TP Standard Account Name</th>
              {years.map((periodStr) => {
                const { month, year } = extractPeriodAndDate(periodStr);

                let displayedPeriod = year;

                if (month) {
                  displayedPeriod = `${month} ${displayedPeriod}`;
                }

                return (
                  <th key={periodStr} className="border px-4 py-2">
                    {displayedPeriod}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {reformattedData
              .filter(({ document_type }) => document_type === activeTab)
              .map(({ bold, tp_standard_account_name, format = defaultFormat, amount }, index) => (
                <tr
                  key={index}
                  className={
                    `${bold && 'font-bold'}`
                  }
                >
                  <td className="border px-4 py-2">
                    {tp_standard_account_name}
                  </td>
                  {years.map((year) => (
                    <td key={year} className="border px-4 py-2 text-right">
                      {format(amount[year] || 0, currency)}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResultStepV2;
