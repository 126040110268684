import backIcon from "../../assets/icons/icon_back.svg";
import Tabs from "../../components/Tabs";
import TextInput from "../../components/TextInput";
import { mockData } from "../FinancialSpreadingUpload/mock_data";

export default function ViewClient() {
  return (
    <div className="w-full max-w-default px-16 relative min-h-[calc(100vh-70px)] flex flex-col">
      <div className="py-[40px] flex gap-[16px]">
        <img src={backIcon} alt="back-button" className="cursor-pointer" />
        <span className="text-gradient-aurora-blue  text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
          Financial Spreads
        </span>
      </div>
      <div className="flex flex-col items-center gap-[32px] w-full">
        <div className="flex flex-col p-[32px] gap-[24px] bg-white max-w-[1186px] w-full rounded-[20px]">
          <div className="flex gap-[24px] w-full">
            <TextInput label="Group Name" className="flex-1" />
            <TextInput label="Company Name" className="flex-1" />
          </div>
          <div className="flex gap-[24px] w-full">
            <TextInput label="Document Currency" className="flex-1" />
            <TextInput label="Output Currency" className="flex-1" />
          </div>
          <div className="flex gap-[24px] w-full">
            <TextInput label="Exchange Rate" className="flex-1" />
            <TextInput label="Unit" className="flex-1" />
          </div>
        </div>
        <div className="max-w-[1186px] w-full flex flex-col gap-[24px]">
          <Tabs />
          <div className="bg-white p-[24px] border-[1px] border-solid rounded-[20px] flex flex-col">
            <span className="leading-[28px] tracking-[-0.6px] font-[700] text-[20px] text-black">
              Financial Mapping
            </span>
            <table className="table-auto">
              <thead className="">
                <tr className="border-b-[1px] border-b-card-on-surface">
                  <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tl-[8px]">
                    <div className="flex items-center gap-[2px]">
                      Aurora Standard Account Name
                    </div>
                  </th>
                  <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50">
                    <div className="flex justify-end float-right w-full">
                      <span>Mar 2023</span>
                    </div>
                  </th>
                  <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tr-[8px]">
                    <div className="flex justify-end">Mar 2022</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {mockData.map(({ clientAccountName, mar2022, mar2023 }) => (
                  <tr>
                    <td>{clientAccountName}</td>
                    <td>
                      <div className="flex justify-end">{mar2022}</div>
                    </td>
                    <td>
                      <div className="flex justify-end">{mar2023}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
