import PropTypes from "prop-types";
import { Fragment } from "react";
import { useDropzone } from "react-dropzone";
import closeIcon from "../../assets/icons/icon_close.svg";
import uploadIcon from "../../assets/icons/icon_upload.svg";
import { FILE_TYPE_ICON_MAP, FILE_TYPE_TEXT_MAP } from "../../constants/file";

Upload.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  file: PropTypes.instanceOf(File),
  accept: PropTypes.arrayOf(PropTypes.string),
};

export default function Upload({
  onFileSelect,
  file,
  accept = [],
  multiple,
  maxSize,
  acceptLabel = "",
}) {
  const { dropzoneAccept, acceptedFilesTexts = [] } = accept.reduce(
    ({ dropzoneAccept = {}, acceptedFilesTexts = [] }, currentAcceptedFile) => {
      const newAcceptedFilesTexts = [...acceptedFilesTexts];
      const currentFileTypeText = FILE_TYPE_TEXT_MAP[currentAcceptedFile];
      if (currentFileTypeText) {
        newAcceptedFilesTexts.push(currentFileTypeText);
      }

      return {
        dropzoneAccept: {
          ...dropzoneAccept,
          [currentAcceptedFile]: [],
        },
        acceptedFilesTexts: newAcceptedFilesTexts,
      };
    },
    {}
  );

  const fileTypeIcon = file && FILE_TYPE_ICON_MAP[file.type];

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      onFileSelect(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: dropzoneAccept,
    multiple,
    maxSize,
  });

  return (
    <Fragment>
      <div
        {...getRootProps()}
        className={`dropzone p-4 border-2 border-dashed rounded-lg ${
          isDragActive ? "border-blue-500" : "border-gray-300"
        }`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="text-center text-blue-500">Drop the file here...</p>
        ) : (
          <Fragment>
            <img src={uploadIcon} alt="Upload" className="mx-auto pb-4" />
            <p className="text-center text-neutral-strong font-[600] leading-[20px] tracking-[-0.28px] text-[14px]">
              Drag & Drop or <span className="text-primary-6">Choose File</span>{" "}
              to upload
            </p>
            <p className="text-center leading-[16px] font-[450] text-[12px] text-neutral-light mt-[8px]">
              {!!(acceptedFilesTexts && acceptedFilesTexts.length) && (
                <span>{acceptLabel ?? acceptedFilesTexts.join(",")}.</span>
              )}
              {maxSize && <span>Max size of {maxSize / (1024 * 1024)}MB</span>}
            </p>
          </Fragment>
        )}
      </div>
      {file && (
        <div className="mt-6 py-3 px-4 bg-black bg-opacity-5 rounded-lg flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <div className="pr-4">
              <img src={fileTypeIcon} alt={file.type} />
            </div>
            <div>
              <p>{file.name}</p>
              <p>{(file.size / 1024).toFixed(2)} KB</p>
            </div>
          </div>
          <div className="flex items-start">
            <button type="button" onClick={() => onFileSelect(null)}>
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
}
