import { useState } from "react";

const activeClass =
  "border-b-[2px] text-primary-2 font-[700] leading-[24px] tracking-[-0.32px] underline decoration-primary-2 decoration-[2px] underline-offset-[19px]";
const defaultTabClass = "cursor-pointer text-[16px] pb-[12px]";
const disabledClass = "text-Text-disabled cursor-not-allowed";

export default function Tabs() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleChangeActiveTabs = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };
  return (
    <div class="w-full flex gap-[24px] border-b-[1px] border-solid border-card-on-surface">
      <span
        className={`${
          activeTabIndex === 0 ? activeClass : ""
        } ${defaultTabClass}`}
        onClick={() => handleChangeActiveTabs(0)}
      >
        Income Statement
      </span>
      <span
        className={`${
          activeTabIndex === 1 ? activeClass : ""
        } ${defaultTabClass}`}
        onClick={() => handleChangeActiveTabs(1)}
      >
        Balance Sheet
      </span>
      <span
        className={`${
          activeTabIndex === 2 ? activeClass : ""
        } ${disabledClass} ${defaultTabClass} `}
      >
        Cast Flow Statement
      </span>
    </div>
  );
}
