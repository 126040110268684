import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import barChartIcon from "../../assets/icons/icon_bar_chart.svg";
import briefcaseIcon from "../../assets/icons/icon_briefcase.svg";
import notesIcon from "../../assets/icons/icon_notes.svg";
import sendIcon from "../../assets/icons/icon_send.svg";
import viewActivityIcon from "../../assets/icons/icon_view_activity.svg";
import {
  URL_CLIENTS,
  // URL_APPLICATION_CREATE,
  URL_CLIENTS_SUMMARY_ACTIVITY,
  URL_CLIENTS_SUMMARY_PORTFOLIO,
  URL_FINANCIAL_SPREADING,
} from "../../constants/url";
import { useAuthContext } from "../../context/Auth";

export default function DashboardPage() {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const cards = [
    {
      title: "Create a New Company",
      description:
        "Set up a new company account and upload initial financial statements effortlessly.",
      icon: briefcaseIcon,
      // TODO: move to new url once implemented
      // onClick: () => navigate(URL_APPLICATION_CREATE),
      onClick: () => navigate(URL_FINANCIAL_SPREADING),
    },
    {
      title: "Manage Existing Companies",
      description:
        "Access and update your accounts: add new financial statements, modify existing ones, or update account details.",
      icon: notesIcon,
      onClick: () => navigate(URL_CLIENTS),
    },
    {
      title: "View Your Activity",
      description:
        "Review your activity: track how many financial assessments you’ve conducted and the companies you’ve analyzed over your selected period.",
      icon: viewActivityIcon,
      onClick: () => navigate(URL_CLIENTS_SUMMARY_ACTIVITY),
    },
    {
      title: "Explore Your Portfolio",
      description:
        "Get a comprehensive overview of your portfolio, including statistical breakdowns by industry, activities, and your watchlist.",
      icon: barChartIcon,
      onClick: () => navigate(URL_CLIENTS_SUMMARY_PORTFOLIO),
    },
  ];

  return (
    <Fragment>
      <div className="bg-page-gradient fixed inset-0 z-[-1]" />
      <div className="px-16 pb-16 flex justify-between flex-col h-screen-minus-header w-full">
        <div className="pt-4">
          <div className="pb-8">
            <div className="pb-[64px]">
              <div>
                <span className="text-gradient-aurora-blue text-[72px] font-[700] tracking-[-2.88px] leading-[80px]">
                  Good morning, {user?.first_name ?? "Kevin"}!
                </span>
              </div>
              <h2 className="text-Gray-500 mt-[16px] font-[700] leading-[48px] text-[48px] tracking-[-1.76px]">
                How can I help you today?
              </h2>
            </div>
            <div
              // className="flex flex-row justify-center gap-[16px] w-full "
              className="flex flex-row justify-center flex-wrap gap-[16px] w-full "
              style={{ transform: `scale(${1})` }}
            >
              {cards.map(({ onClick, description, title, icon }, _) => (
                <div
                  key={title}
                  className="hover:bg-primary-2 cursor-pointer w-[340px] aspect-square p-[2px] rounded-[20px]"
                  onClick={onClick}
                >
                  <div
                    className="bg-card-gradient w-full h-full rounded-[19px] flex flex-col justify-between"
                    style={{
                      padding: 24,
                    }}
                  >
                    <div className="pb-8 text-left">
                      <h3
                        className="text-primary-950 font-[600]"
                        style={{
                          fontSize: 24,
                          lineHeight: "32px",
                          letterSpacing: -0.72,
                          marginBottom: 12,
                        }}
                      >
                        {title}
                      </h3>
                      <p
                        className="text-black-Alpha-600 font-[500]"
                        style={{
                          fontSize: 16,
                          lineHeight: "24px",
                          letterSpacing: -0.32,
                        }}
                      >
                        {description}
                      </p>
                    </div>
                    <div className="flex justify-end w-full">
                      <div
                        className="aspect-square bg-white rounded-full flex items-center border-[7px] border-primary-50 justify-center"
                        style={{
                          width: 80,
                        }}
                      >
                        <img
                          src={icon}
                          alt={title}
                          className="aspect-square"
                          style={{
                            width: 40,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mx-auto w-full max-w-[884px] justify-between flex flex-col gap-[24px]">
          <p className="text-left font-[500] text-[16px] text-neutral-medium">
            For additional actions such as viewing your profile or entering
            direct commands (e.g., ‘I want to add a new set of financial
            statements for Trustplus AI’), please type your instructions
            directly into the text bar below.
          </p>
          <div className="relative w-full">
            <input
              className="bg-white opacity-55"
              placeholder="Type your instructions here"
            />
            <img
              src={sendIcon}
              alt="Send"
              className="w-10 h-10 bottom-1 absolute right-5"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
