export default function formatCurrency (value, currency) {
  if (!currency) {
    return value
  }

  const isNotANumber = isNaN(Number(value))

  if (isNotANumber) {
    return value
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(value);
};