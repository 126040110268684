import { createContext, useContext, useState } from "react"
import Loader from "../../components/Loader"

const LoaderContext = createContext({
  setLoading: (_isLoading) => {},
  isLoading: false,
})

export const useLoader = () => useContext(LoaderContext)

export default function LoaderProvider({ children }) {
    const [isLoading, setLoading] = useState(false)

    return (
        <LoaderContext.Provider value={{ setLoading, isLoading }}>{children}<Loader isLoading={isLoading} /></LoaderContext.Provider>
    )
}