import PropTypes from "prop-types";
import { Fragment } from "react";
import backIcon from "../../assets/icons/icon_back.svg";
import { useToaster } from "../../context/Toaster";

RequestEmail.propTypes = {
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default function RequestEmail({ onBack, onSubmit }) {
  const { showToast } = useToaster();

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const email = formData.get("email");

    return onSubmit(email).catch((err) => {
      console.error("forgot password error:", err);
      const errMsg =
        err.response?.data?.detail?.[0]?.message || "Internal Server Error";
      showToast(errMsg);
    });
  };

  return (
    <Fragment>
      <div className="flex flex-row items-center pb-[20px]">
        <button
          type="button"
          onClick={onBack}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <img src={backIcon} alt="Back" />
        </button>
        <span className="text-gradient-aurora-blue title-forget-password">
          Forgot Password
        </span>
      </div>
      <div>
        <span className="dont-worry">
          Don’t worry. Please provide us your email address and we will send you
          reset instructions.
        </span>
        <form onSubmit={handleSubmit} className="text-left pt-[44px]">
          <div className="pb-[24px]">
            <label htmlFor="email" className="email-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="flex flex-col items-center justify-between">
            <button type="submit" className="primary w-full">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
}
