import Dropdowns from "../Dropdowns";

export default function DatePicker() {
  return (
    <div className="flex flex-col gap-[20px] w-[188px]">
      <div className="flex justify-between items-center">
        <div className="w-[32px] h-[32px] flex items-center justify-center rounded-[50%] border-[1px] border-control-light-hover hover:border-primary-2 hover:bg-primary-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="8"
            viewBox="0 0 6 8"
            fill="none"
          >
            <path
              d="M2.06202 3.64683L1.70963 4L2.06202 4.35317L4.76258 7.05962L4.5293 7.29289L1.2364 4L4.5293 0.707107L4.76258 0.940385L2.06202 3.64683Z"
              fill="#525252"
              stroke="#525252"
            />
          </svg>
        </div>
        <span className="text-[16px] leading-[24px] font-[600] tracking-[-0.32px] text-neutral-strong">
          2023
        </span>
        <div className="w-[32px] h-[32px] flex items-center justify-center rounded-[50%] border-[1px] border-control-light-hover rotate-180 hover:border-primary-2 hover:bg-primary-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="8"
            viewBox="0 0 6 8"
            fill="none"
          >
            <path
              d="M2.06202 3.64683L1.70963 4L2.06202 4.35317L4.76258 7.05962L4.5293 7.29289L1.2364 4L4.5293 0.707107L4.76258 0.940385L2.06202 3.64683Z"
              fill="#525252"
              stroke="#525252"
            />
          </svg>
        </div>
      </div>
      <div className="grid grid-rows-4 grid-flow-col gap-[4px]">
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          Jan
        </span>
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          Feb
        </span>
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          Mar
        </span>
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          Apr
        </span>
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          May
        </span>
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          Jun
        </span>
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          Jul
        </span>
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          Aug
        </span>
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          Sep
        </span>
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          Oct
        </span>
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          Nov
        </span>
        <span className="border-[1px] border-transparent hover:border-primary-2 hover:bg-primary-100 text-center p-[8px] rounded-[8px] bg-black-alpha-100 font-[600] leading-[16px] tracking-[-0.24px] text-primary-strong text-[12px]">
          Dec
        </span>
      </div>
      <Dropdowns
        label="Select number of month"
        defaultValue="12"
        inputClassName="!text-[14px] !leading-[20px] !py-[8px] !px-[16px]"
        className=""
        data={[
          { label: "1" },
          { label: "2" },
          { label: "3" },
          { label: "4" },
          { label: "5" },
          { label: "6" },
          { label: "7" },
          { label: "8" },
          { label: "9" },
          { label: "10" },
          { label: "11" },
          { label: "12" },
        ]}
      />
    </div>
  );
}
