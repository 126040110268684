import { useEffect, useState } from "react";

const mockPendingApplicationsData = [
  {
    companyName: "HB Global",
    id: 'some-id1',
    status: 'IN_PROGRESS'
  },
  {
    companyName: 'Metaverse',
    id: 'some-id2',
    status: 'DONE'
  },
  {
    companyName: 'VinID',
    id: 'some-id3',
    status: 'IN_PROGRESS'
  },
];

export default function usePendingApplications() {
  const [pendingApplications, setPendingApplications] = useState([]);

  useEffect(() => {
    // TODO: call API
    setPendingApplications(mockPendingApplicationsData);
  }, []);

  const hasPendingApplication = pendingApplications.some((pendingApplication) => pendingApplication.status === 'IN_PROGRESS')

  return { pendingApplications, hasPendingApplication }
}
