import backIcon from "../../assets/icons/icon_back.svg";
import iconFilterTable from "../../assets/icons/icon_filter_table.svg";
import iconSearch from "../../assets/icons/icon_search.svg";
import iconTableEye from "../../assets/icons/icon_table_eye.svg";
import iconTablePen from "../../assets/icons/icon_table_pen.svg";
import iconTableTrash from "../../assets/icons/icon_table_trash.svg";
import iconTableUpload from "../../assets/icons/icon_table_upload.svg";

export default function ManageAccounts() {
  return (
    <div className="w-full max-w-default px-16 relative min-h-[calc(100vh-70px)] flex flex-col">
      <div className="py-[40px] flex gap-[16px]">
        <img src={backIcon} alt="back-button" className="cursor-pointer" />
        <span className="text-gradient-aurora-blue  text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
          Company List
        </span>
      </div>
      <form class="w-full max-w-[1186px] mx-auto">
        <div class="relative">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <img src={iconSearch} />
          </div>
          <input
            type="search"
            id="default-search"
            class="pl-[48px] text-[16px] font-[400] leading-[24px] tracking-[-0.32px]"
            placeholder="Search..."
            required
          />
        </div>
      </form>
      <div className="w-full max-w-[1186px] mx-auto mt-[24px] rounded-[20px] border-[1px] border-solid border-card-on-surface bg-white p-[24px]">
        <span className="tracking-[-0.6px] text-[20px] font-[700] leading-[28px]">
          Recent
        </span>
        <table className="table-auto">
          <thead className="">
            <tr className="border-b-[1px] border-b-card-on-surface">
              <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tl-[8px]">
                <div className="flex items-center gap-[2px]">
                  Group name
                  <img src={iconFilterTable} />
                </div>
              </th>
              <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50">
                <div className="flex items-center gap-[2px]">
                  Company name
                  <img src={iconFilterTable} />
                </div>
              </th>
              <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50">
                <div className="flex items-center gap-[2px]">
                  Fiscal Period
                  <img src={iconFilterTable} />
                </div>
              </th>
              <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50">
                <div className="flex items-center gap-[2px]">
                  Date Modified
                  <img src={iconFilterTable} />
                </div>
              </th>
              <th className="flex justify-end text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tr-[8px]">
                <div className="max-w-[200px] w-full text-center">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>HBLAB</td>
              <td>HBGLOBAL</td>
              <td>July 2024</td>
              <td>25 July 2024</td>
              <td className="flex justify-end">
                <div className="flex items-center gap-[4px] justify-center max-w-[200px] w-full">
                  <img src={iconTableEye} className="p-[8px] cursor-pointer" />
                  <img src={iconTablePen} className="p-[8px] cursor-pointer" />
                  <img
                    src={iconTableUpload}
                    className="p-[8px] cursor-pointer"
                  />
                  <img
                    src={iconTableTrash}
                    className="p-[8px] cursor-pointer"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
