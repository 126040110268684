import React, { useState } from "react";
import {
  PencilIcon,
  TrashIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import formatCurrency from "../../utils/currency/formatCurrency";
import extractPeriodAndDate from "../../utils/string/extractPeriodAndDate";

const TableDisplayV2 = ({
  unit,
  currency,
  tpStandardAccountOptions,
  editedData,
  setEditedData,
  documentType,
  summary = [],
  onRowClick = () => {},
  periodOrder = Array.from(
    new Set(
      editedData
        .filter(({ document_type }) => document_type === documentType)
        .flatMap((item) => Object.keys(item.amount))
    )
).sort(),
}) => {
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [newRowIndex, setNewRowIndex] = useState(null);

  const handleAddClick = (index) => {
    const newData = [...editedData];
    const emptyRow = {
      client_account_name: "",
      tp_standard_account_name: "",
      account_code: "",
      document_type: documentType,
      amount: periodOrder.reduce((acc, year) => ({ ...acc, [year]: 0 }), {}),
    };
    newData.splice(index + 1, 0, emptyRow); // Insert the new row right after the clicked row
    setEditedData(newData);
    setEditRowIndex(index + 1);
    setNewRowIndex(index + 1);
  };

  const handleEditClick = (index) => {
    setEditRowIndex(index);
    setOriginalData(JSON.parse(JSON.stringify(editedData[index]))); // Save a deep copy of the data before editing
  };

  const handleSaveClick = () => {
    if (newRowIndex !== null) {
      // Handle saving a new row
      const newData = [...editedData];
      // If tp_standard_account_name is empty, set it to the first option in the dropdown
      if (
        !newData[newRowIndex].tp_standard_account_name &&
        tpStandardAccountOptions.length > 0
      ) {
        newData[newRowIndex].tp_standard_account_name =
          tpStandardAccountOptions[0].tp_standard_account_name;
        newData[newRowIndex].account_code =
          tpStandardAccountOptions[0].account_code;
      }
      setEditedData(newData);
      setNewRowIndex(null);
    }
    setEditRowIndex(null);
    setOriginalData(null);
  };

  const handleCancelClick = () => {
    const newData = [...editedData];
    if (newRowIndex !== null) {
      newData.splice(newRowIndex, 1); // Remove the newly added row
      setNewRowIndex(null);
    } else {
      newData[editRowIndex] = originalData; // Reset the edited row to original data
    }
    setEditedData(newData);
    setEditRowIndex(null);
    setOriginalData(null);
  };

  const handleChange = (e, index, key) => {
    const newData = [...editedData];
    if (index === newRowIndex) {
      if (key === "tp_standard_account_name") {
        const selectedName = e.target.value;
        newData[newRowIndex][key] = selectedName;
        // Find the corresponding account code from tpStandardAccountOptions
        const selectedOption = tpStandardAccountOptions.find(
          (option) => option.tp_standard_account_name === selectedName
        );
        if (selectedOption) {
          newData[newRowIndex].account_code = selectedOption.account_code;
        }
      } else if (key === "client_account_name") {
        newData[newRowIndex][key] = e.target.value;
      } else {
        newData[newRowIndex].amount[key] =
          e.target.value === "" ? 0 : parseFloat(e.target.value);
      }
      setEditedData(newData);
    } else {
      if (key === "tp_standard_account_name") {
        const selectedName = e.target.value;
        newData[index][key] = selectedName;
        // Find the corresponding account code from tpStandardAccountOptions
        const selectedOption = tpStandardAccountOptions.find(
          (option) => option.tp_standard_account_name === selectedName
        );
        if (selectedOption) {
          newData[index].account_code = selectedOption.account_code;
        }
      } else if (key === "client_account_name") {
        newData[index][key] = e.target.value;
      } else {
        newData[index].amount[key] =
          e.target.value === "" ? 0 : parseFloat(e.target.value);
      }
      setEditedData(newData);
    }
  };

  const summaryBodyData = summary.map((item) => ({
    ...item,
    isSummary: true,
  }));

  const handleDeleteClick = (index) => {
    if (window.confirm("Are you sure you want to delete this row?")) {
      const newData = editedData.filter((_, i) => i !== index);
      setEditedData(newData);
    }
  };

  const bodyData = [...editedData, ...summaryBodyData]
    .map((item, index) => {
      const { isSummary } = item;
      if (item.document_type !== documentType) {
        return null;
      }

      if (editRowIndex === index) {
        return (
          <tr key={index}>
            <td>
              <input
                type="text"
                value={item.client_account_name}
                onChange={(e) => handleChange(e, index, "client_account_name")}
                className="large-input"
              />
            </td>
            <td>
              <select
                value={item.tp_standard_account_name}
                onChange={(e) =>
                  handleChange(e, index, "tp_standard_account_name")
                }
                className="large-select"
              >
                {Object.entries(
                  tpStandardAccountOptions.reduce((acc, option) => {
                    const { account_type, tp_standard_account_name, account_code } = option;
                    if (!acc[account_type]) {
                      acc[account_type] = [];
                    }
                    acc[account_type].push(option);
                    return acc;
                  }, {})
                ).map(([account_type, options]) => (
                  <optgroup key={account_type} label={account_type}>
                    {options.map((option) => (
                      <option key={option.account_code} value={option.tp_standard_account_name}>
                        {option.tp_standard_account_name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </td>
            {periodOrder.map((year) => (
              <td key={year} className="right-align">
                <input
                  type="text"
                  value={item.amount[year]}
                  onChange={(e) => handleChange(e, index, year)}
                  className="large-input text-right"
                />
              </td>
            ))}
            <td className="flex space-x-2 h-full align-middle">
              <>
                <button
                  onClick={handleSaveClick}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Save
                </button>
                <button
                  onClick={handleCancelClick}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </>
            </td>
          </tr>
        );
      }

      if (isSummary) {
        return (
          <tr key={index}>
            <td colSpan={2}>{item.tp_standard_account_name}</td>
            {periodOrder.map((year) => (
              <td key={year} className="right-align">
                {formatCurrency(item.amount[year], currency)}
              </td>
            ))}
          </tr>
        );
      }

      return (
        <tr key={index} onClick={onRowClick.bind(null, index)}>
          <td>{item.client_account_name}</td>
          <td>{item.tp_standard_account_name}</td>
          {periodOrder.map((year) => (
            <td key={year} className="right-align">
              {typeof item.amount[year] === "number"
                ? formatCurrency(item.amount[year], currency)
                : JSON.stringify(item.amount[year])}
            </td>
          ))}
            <td className="flex space-x-2 h-full align-middle">
              {
                <>
                  <PlusCircleIcon
                    className="h-5 w-5 text-green-500 cursor-pointer"
                    onClick={() => handleAddClick(index)}
                  />

                  <PencilIcon
                    className="h-5 w-5 text-blue-500 cursor-pointer"
                    onClick={() => handleEditClick(index)}
                  />

                  <TrashIcon
                    className="h-5 w-5 text-red-500 cursor-pointer"
                    onClick={() => handleDeleteClick(index)}
                  />
                </>
              }
            </td>
        </tr>
      );
    })
    .filter(Boolean);

  return (
    <div className="table-container mt-4 p-2 border border-gray-300 rounded-lg">
      {unit ? (
        <div className="text-sm text-left font-semibold text-grey-500 capitalize">
          Unit: {unit}
        </div>
      ) : (
        <></>
      )}

      {currency ? (
        <div className="text-sm text-left font-semibold text-grey-500 capitalize">
          Currency: {currency}
        </div>
      ) : (
        <></>
      )}

      {bodyData.length ? (
        <table className="min-w-full bg-white border-collapse">
          <thead>
            <tr align="center">
              <th className="border text-black px-4 py-2">
                Client Account Name
              </th>
              <th className="border text-black px-4 py-2">
                Aurora Standard Account Name
              </th>
              {periodOrder.map((periodStr) => {
                const { month, year } = extractPeriodAndDate(periodStr)

                let displayedPeriod = year

                if (month) {
                  displayedPeriod = `${month} ${displayedPeriod}`
                }

                return (
                  <th key={periodStr} className="text-black border px-4 py-2">
                    {displayedPeriod}
                  </th>
                );
              })}
              <th className="border text-black px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>{bodyData}</tbody>
        </table>
      ) : (
        <div>Need to upload first</div>
      )}
    </div>
  );
};

export default TableDisplayV2;
