import {
  ACCOUNT_CODE_CONSTANT,
} from "../../constants/formula";
import calculateTotalByOperation from "./calculate_total_by_operation";
import defaultCalculateFormula from "./default_calculate_formula";
 
export default function calculateFormulaFromData(
  data,
  formula,
  baseCalculateMetadata = {},
  baseData = {},
) {
  const aggregatedData = {};

  data.forEach((item) => {
    const key = item.account_code; // You can change this to 'tp_standard_account_name' if needed
    if (!aggregatedData[key]) {
      aggregatedData[key] = { ...item, amount: { ...item.amount } };
      return;
    }

    Object.keys(item.amount).forEach((year) => {
      aggregatedData[key].amount[year] =
        (aggregatedData[key].amount[year] || 0) + item.amount[year];
    });
  });
 
  return formula.map(
    ({ tp_standard_account_name, displayCode, operations, document_type, ...otherMetaData }) => {
      const totals = {};

      operations.forEach((currentOperationData) => {
        const {
          accountCode,
          operation,
          year: usedYear = 0,
          calculate = defaultCalculateFormula,
        } = currentOperationData;

        if (accountCode === ACCOUNT_CODE_CONSTANT) {
          for (const year in totals) {
            const calculateMetadata = {
              ...baseCalculateMetadata,
            };
            const calculatedYear = Number(year) + usedYear;
            calculateMetadata.isOldestYear = !totals[year - 1];
            const value = calculate(calculateMetadata, totals, calculatedYear);
            const currentTotal = totals[year];

            const newTotal = calculateTotalByOperation(
              currentTotal,
              value,
              operation
            );

            totals[year] = newTotal;
          }
        }

        const accountCodeList = accountCode.split(",");

        const accountCodeAmountMap = {};

        let accountCodeAmountMapItems = 0;
         
        const aggregatedFinancialData = Object.values(aggregatedData)

        aggregatedFinancialData.forEach((item) => {
          accountCodeList.forEach((accountCode) => {
            const [startAccountCode, endAccountCode = startAccountCode] =
              accountCode.split("-");
            if (
              item.account_code >= startAccountCode &&
              item.account_code <= endAccountCode
            ) {
              accountCodeAmountMap[item.account_code] = item.amount;
              accountCodeAmountMapItems++;
            }

            if (accountCodeAmountMapItems >= accountCodeList.length) {
              return;
            }
          });
        });

        const yearAccountCodeMap = {};

        for (const accountCode in accountCodeAmountMap) {
          const yearAmounts = accountCodeAmountMap[accountCode];

          for (const year in yearAmounts) {
            if (!yearAccountCodeMap[year]) {
              yearAccountCodeMap[year] = {};
            }

            yearAccountCodeMap[year][accountCode] = yearAmounts[year];
          }
        }

        const accountCodeSum = {};

        for (const year in yearAccountCodeMap) {
          const calculateMetadata = {
            ...baseCalculateMetadata,
          };
          calculateMetadata.isOldestYear = !yearAccountCodeMap[year - 1];

          const calculatedYear = Number(year) + usedYear;
          const amount = yearAccountCodeMap[calculatedYear];

          const result = calculate(
            amount,
            calculateMetadata,
            yearAccountCodeMap,
            calculatedYear
          );
          accountCodeSum[year] = result;
        }

        for (const year in accountCodeSum) {
          const value = accountCodeSum[year];

          if (!value) {
            continue;
          }

          const currentTotal = totals[year];

          const newTotal = calculateTotalByOperation(
            currentTotal,
            value,
            operation
          );

          totals[year] = newTotal;
        } 
      });
 
      const newData = {
        ...otherMetaData,
        tp_standard_account_name: tp_standard_account_name,
        account_code: displayCode,
        amount: totals,
        document_type,
        ...baseData,
      };
    
      aggregatedData[newData.account_code] = newData;

      return newData;
    }
  );
}
