import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import iconCancel from "../../assets/icons/icon_table_cancel.svg";
import iconPlusGreen from "../../assets/icons/icon_table_green_plus.svg";
import iconSave from "../../assets/icons/icon_table_save.svg";
import iconTrash from "../../assets/icons/icon_table_trash.svg";
import tableDateDropDown from "../../assets/icons/table_date_dropdown.png";
import DatePicker from "../DatePicker";
import Popover from "../Popover";
import TableInput from "../TableElements/Input";
import TableSelect from "../TableElements/Select";

export default function CommonTable({ rowsData, label }) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [buttonPosition, setButtonPosition] = useState(null);
  const [editingRows, setEditingRows] = useState({});
  const [rows, setRows] = useState(rowsData);
  const closePopover = () => {
    setPopoverOpen(false);
  };

  const handleSetEditingRows = (id, allowEditName) => {
    setEditingRows((prev) => ({
      ...prev,
      [id]: allowEditName,
    }));
  };

  const handleCancelEditingRow = (id) => {
    setEditingRows((prev) => {
      return {
        ...prev,
        [id]: undefined,
      };
    });
  };

  const handleAddMoreColumn = (indexToAdd) => {
    const newItems = {
      id: uuidv4(),
      clientAccountName: "",
      standardAccountName: "",
      mar2023: "",
      mar2022: "",
    };
    setRows((prev) => {
      const newRows = [
        ...prev.slice(0, indexToAdd),
        newItems,
        ...prev.slice(indexToAdd),
      ];
      return newRows;
    });
    handleSetEditingRows(newItems.id, true);
  };

  const handleRemoveRw = (indexToRemove) => {
    handleCancelEditingRow(rows[indexToRemove].id);
    setRows((prev) => {
      const newRows = [
        ...prev.slice(0, indexToRemove),
        ...prev.slice(indexToRemove + 1),
      ];
      return newRows;
    });
  };

  // Toggle the popover and get the button's position
  const handleButtonClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    setButtonPosition({
      top: rect.top,
      left: rect.left,
      height: rect.height,
    });
    setPopoverOpen(!popoverOpen);
  };

  return (
    <>
      {popoverOpen && buttonPosition && (
        <Popover
          position={buttonPosition}
          closePopover={closePopover}
          children={<DatePicker />}
        />
      )}
      <div className="bg-white p-[24px] border-[1px] border-solid rounded-[20px] flex flex-col">
        <span className="leading-[28px] tracking-[-0.6px] font-[700] text-[20px] text-black">
          {label}
        </span>
        <div className="overflow-x-auto">
          <table className="table-fixed">
            <thead>
              <tr className="border-b-[1px] border-b-card-on-surface">
                <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tl-[8px] w-[216px] sticky left-0">
                  <div className="flex items-center gap-[2px]">
                    Client Account Name
                  </div>
                </th>
                <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 w-[216px] sticky left-[216px]">
                  <div className="flex justify-start float-right w-full">
                    <span>Standard Account Name</span>
                  </div>
                </th>
                <th
                  className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 w-[142px]"
                  onClick={handleButtonClick}
                >
                  <div className="flex flex-col items-end w-full">
                    <div>
                      <div className="flex gap-[8px] items-center">
                        <span className="font-[600] text-[14px] leading-[20px] text-primary-2">
                          Mar 2023
                        </span>
                        <img
                          src={tableDateDropDown}
                          alt=""
                          className="w-[10px] h-[5px]"
                        />
                      </div>
                      <span className="font-[500] text-[12px] leading-[16px] text-primary-2">
                        12 months
                      </span>
                    </div>
                  </div>
                </th>
                <th
                  className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 w-[142px]"
                  onClick={handleButtonClick}
                >
                  <div className="flex flex-col items-end w-full">
                    <div>
                      <div className="flex gap-[8px] items-center">
                        <span className="font-[600] text-[14px] leading-[20px] text-primary-2">
                          Mar 2022
                        </span>
                        <img
                          src={tableDateDropDown}
                          alt=""
                          className="w-[10px] h-[5px]"
                        />
                      </div>
                      <span className="font-[500] text-[12px] leading-[16px] text-primary-2">
                        12 months
                      </span>
                    </div>
                  </div>
                </th>
                <th className="max-w-[120px] text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tr-[8px] w-[120px] sticky right-0">
                  <div className="flex justify-center">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {rows.map(
                (
                  {
                    clientAccountName,
                    standardAccountName,
                    mar2022,
                    mar2023,
                    id,
                  },
                  index
                ) => (
                  <>
                    {editingRows[id] != undefined ? (
                      <tr>
                        <td className="bg-white sticky left-0 !h-[35px] !py-0 !px-[12px] !text-[13px] !leading-[16px]">
                          {editingRows?.[id] ? (
                            <TableInput className="w-[142px]" />
                          ) : (
                            <div className="flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-[216px]">
                              {clientAccountName}
                            </div>
                          )}
                        </td>
                        <td className="bg-white sticky left-[216px] !h-[35px] !py-0 !px-[12px] !text-[13px] !leading-[16px]">
                          <TableSelect
                            defaultValue={standardAccountName}
                            data={[]}
                            className="w-[216px]"
                          />
                        </td>
                        <td className="bg-white !h-[35px] !py-0 !pl-[12px] !pr-0 !text-[13px] !leading-[16px]">
                          <TableInput
                            defaultValue={mar2023}
                            className="w-[142px]"
                          />
                        </td>
                        <td className="bg-white !h-[35px] !py-0 !pl-[12px] !pr-0 !text-[13px] !leading-[16px]">
                          <TableInput
                            defaultValue={mar2022}
                            className="w-[142px]"
                          />
                        </td>
                        <td className="bg-white sticky right-0 w-[120px] !h-[35px] !py-0 !px-[12px]">
                          <div className="flex justify-center gap-[24px] items-center">
                            <img
                              src={iconSave}
                              className="w-[20px]  cursor-pointer"
                              title="Save"
                              alt=""
                            />
                            <img
                              onClick={() => {
                                if (editingRows?.[id]) {
                                  handleRemoveRw(index);
                                  return;
                                }
                                handleCancelEditingRow(id);
                              }}
                              src={iconCancel}
                              className="w-[20px]  cursor-pointer"
                              alt=""
                              title="Cancel"
                            />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          className="bg-white sticky left-0 !h-[35px] !py-0 !px-[12px] !text-[13px] !leading-[16px]"
                          onClick={() => handleSetEditingRows(id, false)}
                        >
                          <div className="flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-[216px]">
                            {clientAccountName}
                          </div>
                        </td>
                        <td
                          className="bg-white sticky left-[216px] !h-[35px] !py-0 !px-[12px] !text-[13px] !leading-[16px]"
                          onClick={() => handleSetEditingRows(id, false)}
                        >
                          <div className="flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-[216px]">
                            {standardAccountName}
                          </div>
                        </td>
                        <td
                          className="bg-white !h-[35px] !py-0 !px-[12px] !text-[13px] !leading-[16px]"
                          onClick={() => handleSetEditingRows(id, false)}
                        >
                          <div className="flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-[142px]">
                            {mar2023}
                          </div>
                        </td>
                        <td
                          className="bg-white !h-[35px] !py-0 !px-[12px] !text-[13px] !leading-[16px]"
                          onClick={() => handleSetEditingRows(id, false)}
                        >
                          <div className="flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-[142px]">
                            {mar2022}
                          </div>
                        </td>
                        <td className="bg-white sticky right-0 w-[120px] !h-[35px] !py-0 !px-[12px]">
                          <div className="flex justify-center gap-[24px] items-center">
                            <img
                              src={iconPlusGreen}
                              onClick={() => handleAddMoreColumn(index + 1)}
                              className="w-[20px]  cursor-pointer"
                              title="Add row below"
                              alt=""
                            />
                            <img
                              src={iconTrash}
                              onClick={() => {
                                handleRemoveRw(index);
                              }}
                              className="w-[20px]  cursor-pointer"
                              alt=""
                              title="Delete this row"
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )
              )}
            </tbody>
          </table>
          <table className="table-fixed">
            <thead>
              <tr className="border-b-[1px] border-b-card-on-surface">
                <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tl-[8px] w-[432px] sticky left-0">
                  <div className="flex items-center gap-[2px]">
                    Please Confirm the Accuracy of the Calculated Figures
                  </div>
                </th>
                <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 w-[142px]"></th>
                <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 w-[142px]"></th>
                <th className="max-w-[120px] text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tr-[8px] w-[120px] sticky right-0"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-white sticky left-0 !h-[35px] !py-0 !px-[12px] !text-[13px] !leading-[16px]">
                  <div className="flex justify-start text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-[432px]">
                    Operating Income
                  </div>
                </td>
                <td className="bg-white !h-[35px] !py-0 !px-[12px] !text-[13px] !leading-[16px]">
                  <div className="flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-[142px]">
                    $300
                  </div>
                </td>
                <td className="bg-white !h-[35px] !py-0 !px-[12px] !text-[13px] !leading-[16px]">
                  <div className="flex justify-end text-neutral-strong leading-[20px] tracking-[-0.28px] font-[400] w-[142px]">
                    $300
                  </div>
                </td>
                <td className="bg-white sticky right-0 w-[120px] !h-[35px] !py-0 !px-[12px] flex justify-end items-center">
                  <button className="default border-[1px] border-neutral-medium !px-[14px] !py-[5px] font-[600] leading-[16px] text-[12px] tracking-[-0.24px]">
                    Confirm
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
