import React, { useEffect, useRef } from "react";

const Popover = ({ position, closePopover, children }) => {
  const popoverRef = useRef();

  // Handle outside click to close popover
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        closePopover(); // Close popover when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closePopover]);

  return (
    <div
      ref={popoverRef}
      style={{
        position: "fixed",
        top: position.top + position.height,
        left: position.left,
      }}
      className="z-50 p-[16px] rounded-[16px] shadow-popover bg-white"
    >
      {children}
    </div>
  );
};

export default Popover;
