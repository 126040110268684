export default function extractPeriodAndDate(periodString) {
  let period = null;

    // Regular expression pattern to match <number>M MM YYYY
    const pattern = /(?:([\d]+M)\s)?([A-Za-z]+\s)?(\d{4})/;
    const match = periodString.toString().match(pattern);

    if (match) {
        period = match[1];
        const month = match[2]?.trim();
        const year = match[3];

        // Try to create a valid date object to check the validity of month and year
        const dateString = `${month} ${year}`;
        const date = new Date(dateString);

        if (!isNaN(date.getTime())) {
            return {
              period,
              month,
              year,
            }
        } else {
            return {};
        }
    }

    return {};
}
