import pdfIcon from '../assets/icons/icon_pdf.svg'
import imageIcon from '../assets/icons/icon_image.svg'

export const FILE_TYPE_IMAGE = 'image/*'
export const FILE_TYPE_PDF = 'application/pdf'

export const FILE_TYPE_TEXT_MAP = {
  [FILE_TYPE_IMAGE]: 'Image (e.g. JPG, PNG)',
  [FILE_TYPE_PDF]: 'PDF',
}

export const FILE_TYPE_ICON_MAP = {
  [FILE_TYPE_IMAGE]: imageIcon,
  [FILE_TYPE_PDF]: pdfIcon,
}
