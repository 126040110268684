export default function defaultCalculateFormula(
  accountCodeAmountMap,
) {
  let total = 0

  for (const accountCode in accountCodeAmountMap) {
    const amount = accountCodeAmountMap[accountCode];

    total += amount
  }

  return total;
}
