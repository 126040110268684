import { DOCUMENT_TYPE_ENUM } from "../constants";
import { addPercentage, formatMultiple, formatNumber, negativeBrackets, roundDecimal } from "../utils/formula/format_amount";
import processNumber from "../utils/number/process_number";

export const FORMULA_CALCULATE_OPERATIONS = {
  ADD: "add",
  SUBSTRACT: "substract",
  DIVIDE: "divide",
  MULTIPLY: "multiply",
  PRINT: "print",
};

export const ACCOUNT_CODE_CONSTANT = "CONSTANT";

export const INCOME_STATEMENT_SUBGROUP_FORMULA = [
  {
    bold: true,
    operations: [
      { accountCode: "10.1.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      {
        accountCode: "10.2.00",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "10.3.00",
    tp_standard_account_name: "Gross Profit",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.3.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.3.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.3.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.3.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.3.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.3.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.3.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.3.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.3.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "10.3.99",
    tp_standard_account_name: "Total Operating Expenses",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.3.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.3.01", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      {
        accountCode: "10.3.99",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "10.4.00",
    tp_standard_account_name: "Net Operating Profit",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.4.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.4.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "10.4.99",
    tp_standard_account_name: "Total Net Interest Income (Expense)",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.5.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.5.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.5.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.5.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.5.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.5.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "10.5.99",
    tp_standard_account_name: "Total Other Income (Expense)",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.4.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.4.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "10.5.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "10.6.00",
    tp_standard_account_name: "Profit (Loss) Before Tax",
  },
  {
    bold: true,
    operations: [
      { accountCode: "10.6.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      {
        accountCode: "10.6.10",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "10.7.00",
    tp_standard_account_name: "Net Profit (Loss)",
  },
];

export const BALANCE_SHEET_SUBGROUP_FORMULA = [
  {
    bold: true,
    operations: [
      { accountCode: "20.1.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.1.99",
    tp_standard_account_name: "Total Current Assets",
  },
  {
    bold: true,
    operations: [
      { accountCode: "20.2.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.26", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.27", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.28", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.2.99",
    tp_standard_account_name: "Total Non-Current Assets",
  },
  {
    bold: true,
    operations: [
      { accountCode: "20.1.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.1.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.26", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.27", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.28", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.2.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.3.00",
    tp_standard_account_name: "Total Assets",
  },
  {
    bold: true,
    groupName: "Total Current Liabilities",
    operations: [
      { accountCode: "20.4.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.4.99",
    tp_standard_account_name: "Total Current Liabilities",
  },
  {
    bold: true,
    groupName: "Total Non-Current Liabilities",
    operations: [
      { accountCode: "20.5.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.5.99",
    tp_standard_account_name: "Total Non-Current Liabilities",
  },
  {
    bold: true,
    groupName: "Total Liabilities",
    operations: [
      { accountCode: "20.4.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.4.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.5.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.6.00",
    tp_standard_account_name: "Total Liabilities",
  },
  {
    bold: true,
    groupName: "Total Equity",
    operations: [
      { accountCode: "20.7.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.91", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "20.7.92", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "20.7.99",
    tp_standard_account_name: "Total Equity",
  },
];

export const CASH_FLOW_SUBGROUP_FORMULA = [
  {
    bold: true,
    groupName:
      "Adjustment For Non-Cash Items and Financing And Investing Activities",
    operations: [
      { accountCode: "30.1.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.1.01", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.2.99",
    tp_standard_account_name:
      "Adjustment For Non-Cash Items and Financing And Investing Activities",
  },
  {
    bold: true,
    groupName: "Changes In Operating-Related Assets and Liabilities",
    operations: [
      { accountCode: "30.3.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.26", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.27", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.28", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.3.99",
    tp_standard_account_name:
      "Changes in Operating-Related Assets and Liabilities",
  },
  {
    bold: true,
    groupName: "Net Cashflow Generated From Operating Activities",
    operations: [
      { accountCode: "30.1.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.1.01", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.26", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.27", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.28", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.4.99",
    tp_standard_account_name:
      "Net Cash Flow Generated From Operating Activities",
  },
  {
    bold: true,
    groupName: "Net Cash Flow From Investing Activities",
    operations: [
      { accountCode: "30.5.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.26", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.27", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.5.99",
    tp_standard_account_name: "Net Cash Flow From Investing Activities",
  },
  {
    bold: true,
    groupName: "Net Cash Flow From Financing Activities",
    operations: [
      { accountCode: "30.6.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.91", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.92", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.6.99",
    tp_standard_account_name: "Net Cash Flow From Financing Activities",
  },
  {
    bold: true,
    operations: [
      { accountCode: "30.1.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.1.01", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.26", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.27", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.28", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.26", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.27", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.91", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.92", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.7.00",
    tp_standard_account_name:
      "Net Increase / (Decrease) In Cash and Cash Equivalents",
  },
  {
    bold: true,
    groupName: "Cash and Cash Equivalents At the End of Year",
    operations: [
      { accountCode: "30.1.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.1.01", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.2.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.26", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.27", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.28", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.3.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.26", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.5.27", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.90", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.91", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.6.92", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.7.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "30.7.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "30.8.00",
    tp_standard_account_name: "Cash and Cash Equivalents at End of Year",
  },
];

export const CASH_FLOW_GENERATED_SUBGROUP_FORMULA = [
  {
    bold: true,
    operations: [
      { accountCode: "10.7.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "40.1.00",
    tp_standard_account_name: "NET PROFIT (LOSS)",
  },
  {
    operations: [
      { accountCode: "10.3.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "40.2.10",
    tp_standard_account_name: "Depreciation and Amortisation Expenses",
  },
  {
    operations: [
      { accountCode: "10.3.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "40.2.11",
    tp_standard_account_name: "Impairment Loss",
  },
  {
    operations: [
      {
        accountCode: "10.5.10",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.2.12",
    tp_standard_account_name: "Share of losses (profits) from Associates",
  },
  {
    operations: [
      {
        accountCode: "10.5.11",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.2.13",
    tp_standard_account_name: "Dividend Income",
  },
  {
    operations: [
      {
        accountCode: "10.5.13",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.2.14",
    tp_standard_account_name: "Loss (Gain) on Disposal of Fixed Assets",
  },
  {
    operations: [
      {
        accountCode: "10.5.14",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.2.15",
    tp_standard_account_name: "Loss (Gain) on Investments",
  },
  {
    bold: true,
    operations: [
      { accountCode: "40.2.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.2.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.2.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.2.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.2.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.2.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "40.2.99",
    tp_standard_account_name:
      "ADJUSTMENT FOR NON-CASH ITEMS AND FINANCING AND INVESTING ACTIVITIES",
  },
  {
    operations: [
      {
        accountCode: "20.1.13",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.10",
    tp_standard_account_name: "Change in Trade Receivables",
  },
  {
    operations: [
      {
        accountCode: "20.1.14",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.11",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.11",
    tp_standard_account_name: "Change in Other Receivables",
  },
  {
    operations: [
      {
        accountCode: "20.1.15",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.12",
    tp_standard_account_name: "Change in Inventories",
  },
  {
    operations: [
      {
        accountCode: "20.1.18",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.25",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.25",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.13",
    tp_standard_account_name: "Change in Prepayments",
  },
  {
    operations: [
      {
        accountCode: "20.1.16,20.1.17,20.1.22,20.1.90,20.2.26,20.2.90",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.16,20.1.17,20.1.22,20.1.90,20.2.26,20.2.90",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.14",
    tp_standard_account_name: "Change in Other Operating Assets",
  },
  {
    operations: [
      {
        accountCode: "20.4.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.3.15",
    tp_standard_account_name: "Change in Trade Payables",
  },
  {
    operations: [
      {
        accountCode: "20.4.11",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.3.16",
    tp_standard_account_name: "Change in Other Payables",
  },
  {
    operations: [
      {
        accountCode: "20.4.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.18",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.15",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.17",
    tp_standard_account_name: "Change in Deferred Revenue",
  },
  {
    operations: [
      {
        accountCode: "20.4.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.90",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.19",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.90",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.12",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.14",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.90",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.18",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.19",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.90",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.3.18",
    tp_standard_account_name: "Change in Other Operating Liabilities",
  },
  {
    bold: true,
    operations: [
      {
        accountCode: "40.3.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.3.99",
    tp_standard_account_name:
      "CHANGES IN OPERATING-RELATED ASSETS AND LIABILITIES",
  },
  {
    bold: true,
    operations: [
      {
        accountCode: "40.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.2.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.3.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.4.99",
    tp_standard_account_name:
      "NET CASH FLOW GENERATED FROM OPERATING ACTIVITIES",
  },
  {
    operations: [
      {
        accountCode: "10.5.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.10",
    tp_standard_account_name: "Dividend Income",
  },
  {
    operations: [
      {
        accountCode: "10.5.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.11",
    tp_standard_account_name: "Gain (Loss) on Disposal of Fixed Assets",
  },
  {
    operations: [
      {
        accountCode: "10.5.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.12",
    tp_standard_account_name: "Gain (Loss) on Investments",
  },
  {
    operations: [
      {
        accountCode: "20.2.12",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.13",
    tp_standard_account_name:
      "Disposal (Purchase) of Property, Plant and Equipment",
  },
  {
    operations: [
      {
        accountCode: "20.2.13",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.14",
    tp_standard_account_name: "Disposal (Purchase) of Leasehold improvements",
  },
  {
    operations: [
      {
        accountCode: "20.2.14",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.15",
    tp_standard_account_name: "Disposal (Purchase) of Fixtures and fittings",
  },
  {
    operations: [
      {
        accountCode: "20.2.15",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.16",
    tp_standard_account_name: "Disposal (Purchase) of Vehicles",
  },
  {
    operations: [
      {
        accountCode: "20.2.16",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.17",
    tp_standard_account_name: "Decrease (Increase) in Goodwill",
  },
  {
    operations: [
      {
        accountCode: "20.2.17",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.18",
    tp_standard_account_name:
      "Disposal (Purchase) of Intangible Assets other than Goodwill",
  },
  {
    operations: [
      {
        accountCode: "20.2.19",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.19",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.19",
    tp_standard_account_name:
      "Decrease (Increase) in Investments in subsidiaries, joint ventures and associates",
  },
  {
    operations: [
      {
        accountCode: "20.1.19",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.20",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.19",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.20",
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.20",
    tp_standard_account_name:
      "Decrease (Increase) in Amounts due from shareholders and directors",
  },
  {
    operations: [
      {
        accountCode: "20.2.20",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.21",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.20",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.21",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.21",
    tp_standard_account_name:
      "Decrease (Increase) in Amounts due from subsidiaries",
  },
  {
    operations: [
      {
        accountCode: "20.2.21",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.22",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.21",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.22",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.22",
    tp_standard_account_name:
      "Decrease (Increase) in Amounts due from associates or other related parties",
  },
  {
    operations: [
      {
        accountCode: "20.1.12",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.23",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.23",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.23",
    tp_standard_account_name:
      "Disposal (Purchase) of Securities & Other Financial Assets",
  },
  {
    operations: [
      {
        accountCode: "20.2.24",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.24",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.24",
    tp_standard_account_name:
      "Disposal (Purchase) of Other Non-Current investments",
  },
  {
    operations: [
      {
        accountCode: "20.1.91",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.91",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.91",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.91",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.25",
    tp_standard_account_name: "Decrease (Increase) in Assets held-for-sale",
  },
  {
    operations: [
      {
        accountCode: "20.1.92",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.92",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.92",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.2.92",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.5.26",
    tp_standard_account_name:
      "Decrease (Increase) in Assets of discontinued operations",
  },
  {
    operations: [
      {
        accountCode: "20.4.22",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.14",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.22",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.27",
    tp_standard_account_name:
      "Increase (Decrease) in Amounts due to associates or other related parties",
  },
  {
    operations: [
      {
        accountCode: "20.4.91",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.91",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.91",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.91",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.28",
    tp_standard_account_name:
      "Increase (Decrease) in Liabilities held-for-sale",
  },
  {
    operations: [
      {
        accountCode: "20.4.92",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.92",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.92",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.92",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.5.29",
    tp_standard_account_name:
      "Increase (Decrease) in Liabilities of discontinued operations",
  },
  {
    bold: true,
    operations: [
      { accountCode: "40.5.10", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.11", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.12", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.13", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.14", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.15", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.16", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.17", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.18", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.19", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.20", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.21", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.22", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.23", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.24", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.25", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.26", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.27", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.28", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      { accountCode: "40.5.29", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
    ],
    displayCode: "40.5.99",
    tp_standard_account_name: "NET CASH FLOW FROM INVESTING ACTIVITIES",
  },
  {
    operations: [
      {
        accountCode: "20.4.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.13",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.10",
    tp_standard_account_name: "Increase (Decrease) in Dividend Payables",
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.5.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.15,20.5.16",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.11",
    tp_standard_account_name: "Increase (Decrease) in Bank loans",
  },
  {
    operations: [
      {
        accountCode: "20.4.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.16",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.12",
    tp_standard_account_name: "Increase (Decrease) in Short-term debt",
  },
  {
    operations: [
      {
        accountCode: "20.4.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.17",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.17",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.13",
    tp_standard_account_name: "Increase (Decrease) in Long-term debt",
  },
  {
    operations: [
      {
        accountCode: "20.4.19,20.5.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.19,20.5.11",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.14",
    tp_standard_account_name: "Increase (Decrease) in Finance/Operating leases",
  },
  {
    operations: [
      {
        accountCode: "20.4.20",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.20",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.12",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.15",
    tp_standard_account_name:
      "Increase (Decrease) in Amounts due to shareholders / directors",
  },
  {
    operations: [
      {
        accountCode: "20.4.21",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.5.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.21",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.5.13",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.16",
    tp_standard_account_name:
      "Increase (Decrease) in Amounts due to subsidiaries",
  },
  {
    operations: [
      {
        accountCode: "20.7.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.17",
    tp_standard_account_name: "Increase (Decrease) in Common Share Capital",
  },
  {
    operations: [
      {
        accountCode: "20.7.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.11",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.18",
    tp_standard_account_name: "Increase (Decrease) in Preferred Shares",
  },
  {
    operations: [
      {
        accountCode: "20.7.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.12",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.19",
    tp_standard_account_name: "Increase (Decrease) in Treasury Shares",
  },
  {
    operations: [
      {
        accountCode: "20.7.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.13",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.20",
    tp_standard_account_name: "Increase (Decrease) in Share Premium",
  },
  {
    operations: [
      {
        accountCode: "20.7.90",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.90",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.21",
    tp_standard_account_name: "Increase (Decrease) in Other Equity",
  },
  {
    operations: [
      {
        accountCode: "20.7.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.15",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.22",
    tp_standard_account_name:
      "Increase (Decrease) in Accumulated Other Comprehensive Income (Loss)",
  },
  {
    operations: [
      {
        accountCode: "20.7.91",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.91",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.6.23",
    tp_standard_account_name: "Increase (Decrease) in Other Reserves",
  },
  {
    bold: true,
    operations: [
      {
        accountCode: "40.6.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.14",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.19",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.20",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.21",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.22",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.23",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.6.99",
    tp_standard_account_name: "NET CASH FLOW FROM FINANCING ACTIVITIES",
  },
  {
    operations: [
      {
        accountCode: "20.1.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.11",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.8.10",
    tp_standard_account_name: "Cash and cash equivalents at beginning of year",
  },
  {
    bold: true,
    operations: [
      {
        accountCode: "20.1.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.9.00",
    tp_standard_account_name: "CASH AND CASH EQUIVALENTS AT END OF YEAR",
  },
  {
    bold: true,
    operations: [
      {
        accountCode: "40.9.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.8.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "40.7.10",
    tp_standard_account_name:
      "NET INCREASE / (DECREASE) IN CASH AND CASH EQUIVALENTS",
  },
  {
    operations: [
      {
        accountCode: "40.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.5.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.6.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.7.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "40.7.01",
    tp_standard_account_name: "Unspecified cash flow movements",
  },
];
export const KEY_RATIOS_SUBGROUP_FORMULA = [
  {
    operations: [
      {
        accountCode: "20.1.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.1.10",
    tp_standard_account_name: "Quick Ratio (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "20.1.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.1.11",
    tp_standard_account_name: "Current Ratio (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "20.1.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "50.1.12",
    tp_standard_account_name: "Working Capital",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 0), negativeBrackets, processNumber.bind(null, formatNumber)])
  },
  {
    operations: [
      {
        accountCode: "40.4.99,40.5.13-40.5.16",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowGenerated =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_GENERATED];
          let totalAmount = 0;
          if (isCashFlowGenerated) {
            for (const accountCode in accountCodeAmountMap) {
              const amount = accountCodeAmountMap[accountCode];
              totalAmount += amount;
            }
          }
          return totalAmount;
        },
      },
      {
        accountCode: "30.4.99,30.5.19-30.5.23",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];
          let totalAmount = 0;
          if (isCashFlowUploaded) {
            for (const accountCode in accountCodeAmountMap) {
              const amount = accountCodeAmountMap[accountCode];
              totalAmount += amount;
            }
          }
          return totalAmount;
        },
      },
    ],
    displayCode: "50.1.13",
    tp_standard_account_name: "Free Cash Flow",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 0), negativeBrackets, processNumber.bind(null, formatNumber)])
  },
  {
    operations: [
      {
        accountCode: "20.1.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "40.4.99,30.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap, metadata, totals, calculatedYear) => {
          let opNetCashFlow = accountCodeAmountMap["40.4.99"];
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];

          if (isCashFlowUploaded) {
            opNetCashFlow = accountCodeAmountMap["30.4.99"];
          }

          return -1 * (opNetCashFlow / metadata.numberOfMonths);
        },
      },
      {
        accountCode: "40.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap) => {
          const amount = accountCodeAmountMap["40.4.99"] || 0;
          if (amount >= 0) {
            return "N/A";
          }

          return 0;
        },
      },
    ],
    displayCode: "50.1.90",
    tp_standard_account_name: "Cash Runway (months)",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 0), negativeBrackets, processNumber.bind(null, formatNumber)])
  },
  {
    operations: [
      {
        accountCode: "20.1.13,20.2.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.13,20.2.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (metadata) => {
          if (metadata.isOldestYear) {
            return 1;
          }

          return 2;
        },
      },
      {
        accountCode: "10.1.00",
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap, metadata) => {
          const revenue = accountCodeAmountMap["10.1.00"];

          return (12 * revenue) / metadata.numberOfMonths;
        },
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.MULTIPLY,
        calculate: () => 365,
      },
    ],
    displayCode: "50.2.10",
    tp_standard_account_name: "Trade Receivable Days",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 0), negativeBrackets, processNumber.bind(null, formatNumber)])
  },
  {
    operations: [
      {
        accountCode: "20.1.15",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.15",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (metadata) => {
          if (metadata.isOldestYear) {
            return 1;
          }

          return 2;
        },
      },
      {
        accountCode: "10.2.00",
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap, metadata) => {
          const revenue = accountCodeAmountMap["10.1.00"];

          return (12 * revenue) / metadata.numberOfMonths;
        },
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.MULTIPLY,
        calculate: () => 365,
      },
    ],
    displayCode: "50.2.11",
    tp_standard_account_name: "Inventory Days",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 0), negativeBrackets, processNumber.bind(null, formatNumber)])
  },
  {
    operations: [
      {
        accountCode: "20.4.10,20.5.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.4.10,20.5.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.15",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (metadata) => {
          if (metadata.isOldestYear) {
            return 1;
          }

          return 2;
        },
      },
      {
        accountCode: "10.2.00",
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap, metadata) => {
          const revenue = accountCodeAmountMap["10.1.00"];

          return (12 * revenue) / metadata.numberOfMonths;
        },
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.MULTIPLY,
        calculate: () => 365,
      },
    ],
    displayCode: "50.2.12",
    tp_standard_account_name: "Trade Payable Days",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 0), negativeBrackets, processNumber.bind(null, formatNumber)])
  },
  {
    operations: [
      {
        accountCode: "50.2.10,50.2.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "50.2.12",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "50.2.13",
    tp_standard_account_name: "Working Capital Cycle (Days)",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 0), negativeBrackets, processNumber.bind(null, formatNumber)])
  },
  {
    operations: [
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        calculate: () => {
          return 1;
        },
      },
    ],
    displayCode: "50.3.10",
    tp_standard_account_name: "Revenue Growth (%)",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 1), addPercentage])
  },
  {
    operations: [
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.4.00",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        calculate: () => 1,
      },
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (
          accountCodeAmountMap,
          _,
          accountCodeData,
          calculatedYear
        ) => {
          const ebitCode = "10.4.00";

          const ebit = accountCodeAmountMap[ebitCode];
          const previousEbit = accountCodeData[calculatedYear - 1]?.[ebitCode];

          if (!previousEbit) {
            return "N/A";
          }

          if (ebit >= 0) {
            return "";
          }

          if (ebit > previousEbit) {
            return "Positive change";
          }

          if (ebit < previousEbit) {
            return "Negative change";
          }

          if (ebit === previousEbit) {
            return "Same";
          }

          return "";
        },
      },
    ],
    displayCode: "50.3.12",
    tp_standard_account_name: "EBIT Growth (%)",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 1), addPercentage])
  },
  {
    operations: [
      {
        accountCode: "10.7.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.7.00",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        calculate: () => 1,
      },
      {
        accountCode: "10.7.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (
          accountCodeAmountMap,
          _,
          accountCodeData,
          calculatedYear
        ) => {
          const ebitCode = "10.7.00";

          const currentAmount = accountCodeAmountMap[ebitCode];
          const previousAmount =
            accountCodeData[calculatedYear - 1]?.[ebitCode];

          if (!previousAmount) {
            return "N/A";
          }

          if (currentAmount >= 0) {
            return "";
          }

          if (currentAmount > previousAmount) {
            return "Positive change";
          }

          if (currentAmount < previousAmount) {
            return "Negative change";
          }

          if (currentAmount === previousAmount) {
            return "Same";
          }

          return "";
        },
      },
    ],
    displayCode: "50.3.13",
    tp_standard_account_name: "Net Profit Growth (%)",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 1), addPercentage])
  },
  {
    operations: [
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "30.2.11,30.2.12,30.2.13",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];

          if (isCashFlowUploaded) {
            return Object.values(accountCodeAmountMap).reduce(
              (total, amount) => {
                return total + amount;
              },
              0
            );
          }

          return 0;
        },
      },
      {
        accountCode: "10.3.16,10.3.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowGenerated =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_GENERATED];

          if (isCashFlowGenerated) {
            return Object.values(accountCodeAmountMap).reduce(
              (total, amount) => {
                return total + amount;
              },
              0
            );
          }

          return 0;
        },
      },
    ],
    displayCode: "50.4.10",
    tp_standard_account_name: "EBITDA",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 0), negativeBrackets, processNumber.bind(null, formatNumber)])
  },
  {
    operations: [
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "50.4.10",
        year: -1,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        calculate: () => 1,
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (
          accountCodeAmountMap,
          _,
          accountCodeData,
          calculatedYear
        ) => {
          const ebitCode = "50.4.10";

          const currentAmount = accountCodeAmountMap[ebitCode];
          const previousAmount =
            accountCodeData[calculatedYear - 1]?.[ebitCode];

          if (!previousAmount) {
            return "N/A";
          }

          if (currentAmount >= 0) {
            return "";
          }

          if (currentAmount > previousAmount) {
            return "Positive change";
          }

          if (currentAmount < previousAmount) {
            return "Negative change";
          }

          if (currentAmount === previousAmount) {
            return "Same";
          }

          return "";
        },
      },
    ],
    displayCode: "50.3.11",
    tp_standard_account_name: "EBITDA Growth (%)",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 1), addPercentage])
  },
  {
    operations: [
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
    ],
    displayCode: "50.4.11",
    tp_standard_account_name: "EBIT",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 0), negativeBrackets, processNumber.bind(null, formatNumber)])
  },
  {
    operations: [
      {
        accountCode: "10.3.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.4.12",
    tp_standard_account_name: "Gross Profit Margin (%)",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 1), addPercentage])
  },
  {
    operations: [
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.4.13",
    tp_standard_account_name: "EBITDA Margin (%)",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 1), addPercentage])
  },
  {
    operations: [
      {
        accountCode: "50.4.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.4.14",
    tp_standard_account_name: "EBIT Margin (%)",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 1), addPercentage])
  },
  {
    operations: [
      {
        accountCode: "10.6.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.4.15",
    tp_standard_account_name: "Profit Before Tax Margin (%)",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 1), addPercentage])
  },
  {
    operations: [
      {
        accountCode: "10.7.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.1.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.4.16",
    tp_standard_account_name: "Net Profit Margin (%)",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 1), addPercentage])
  },
  {
    operations: [
      {
        accountCode: "20.7.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.2.16,20.2.17,20.2.18",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
    ],
    displayCode: "50.5.10",
    tp_standard_account_name: "Tangible Net Worth",
    format: formatMultiple.bind(null, [roundDecimal.bind(null, 0), negativeBrackets, processNumber.bind(null, formatNumber)])
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.5.11",
    tp_standard_account_name: "Debt / Net Worth (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "20.7.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.5.12",
    tp_standard_account_name: "Net Debt / Net Worth (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "20.6.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.7.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.5.13",
    tp_standard_account_name: "Total Liabilities / Net Worth (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "50.5.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.5.14",
    tp_standard_account_name: "Debt / Tangible Net Worth (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "50.5.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.5.15",
    tp_standard_account_name: "Net Debt / Tangible Net Worth (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap, metadata) => {
          const amount = accountCodeAmountMap["50.4.10"];
          return amount / (metadata.numberOfMonths * 12);
        },
      },
    ],
    displayCode: "50.5.16",
    tp_standard_account_name: "Debt / EBITDA (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.1.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap, metadata) => {
          const amount = accountCodeAmountMap["50.4.10"];
          return amount / (metadata.numberOfMonths * 12);
        },
      },
    ],
    displayCode: "50.5.17",
    tp_standard_account_name: "Net Debt / EBITDA (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "20.6.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "20.3.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
    ],
    displayCode: "50.5.18",
    tp_standard_account_name: "Total Liabilities / Total Assets (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.4.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap) => {
          const value = accountCodeAmountMap["10.4.11"];
          return value * -1;
        },
      },
      {
        accountCode: "10.4.00",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap) => {
          const value = accountCodeAmountMap["10.4.00"];
          if (value < 0) {
            return "N/A";
          }

          return "";
        },
      },
    ],
    displayCode: "50.6.10",
    tp_standard_account_name: "EBIT to Interest Coverage Ratio (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: "10.4.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap) => {
          const value = accountCodeAmountMap["10.4.11"];
          return value * -1;
        },
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap) => {
          const value = accountCodeAmountMap["50.4.10"];
          if (value < 0) {
            return "N/A";
          }

          return "";
        },
      },
    ],
    displayCode: "50.6.11",
    tp_standard_account_name: "EBITDA to Interest Coverage Ratio (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "30.4.99,40.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];

          if (isCashFlowUploaded) {
            return accountCodeAmountMap["30.4.99"];
          }

          return accountCodeAmountMap["40.4.99"];
        },
      },
      {
        accountCode: "10.4.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap) => {
          const value = accountCodeAmountMap["10.4.11"];
          return value * -1;
        },
      },
      {
        accountCode: "30.4.99,40.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];

          let value = accountCodeAmountMap["40.4.99"];

          if (isCashFlowUploaded) {
            value = accountCodeAmountMap["30.4.99"];
          }

          if (value < 0) {
            return "N/A";
          }

          return "";
        },
      },
    ],
    displayCode: "50.6.12",
    tp_standard_account_name:
      "Operating Cashflow to Interest Coverage Ratio (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (metadata) => metadata.numberOfMonths / 12,
      },
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap) => {
          return Object.values(accountCodeAmountMap).reduce((total, amount) => {
            return total + amount;
          }, 0);
        },
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap) => {
          const value = accountCodeAmountMap["50.4.10"];
          if (value < 0) {
            return "N/A";
          }

          return "";
        },
      },
    ],
    displayCode: "50.6.13",
    tp_standard_account_name: "EBITDA to Debt (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "30.4.99,40.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];

          if (isCashFlowUploaded) {
            return accountCodeAmountMap["30.4.99"];
          }

          return accountCodeAmountMap["40.4.99"];
        },
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (metadata) => metadata.numberOfMonths / 12,
      },
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,20.5.11,20.5.16,20.5.17",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
      },
      {
        accountCode: "30.4.99,40.4.99",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap, metadata) => {
          const isCashFlowUploaded =
            metadata[DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT];

          let value = accountCodeAmountMap["40.4.99"];

          if (isCashFlowUploaded) {
            value = accountCodeAmountMap["30.4.99"];
          }

          if (value < 0) {
            return "N/A";
          }

          return "";
        },
      },
    ],
    displayCode: "50.6.14",
    tp_standard_account_name: "Operating Cashflow to Debt (x)",
    format: roundDecimal.bind(null, 2),
  },
  {
    operations: [
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.ADD,
      },
      {
        accountCode: ACCOUNT_CODE_CONSTANT,
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (metadata) => metadata.numberOfMonths / 12,
      },
      {
        accountCode: "20.4.15,20.4.16,20.4.17,20.4.19,10.4.11",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.DIVIDE,
        calculate: (accountCodeAmountMap) => {
          const negativeValuesMap = {
            "10.4.11": true,
          };

          const { positive, negative } = Object.keys(
            accountCodeAmountMap
          ).reduce(
            ({ positive, negative }, accountCode) => {
              const amount = accountCodeAmountMap[accountCode];
              if (negativeValuesMap[accountCode]) {
                return {
                  positive,
                  negative: negative + amount,
                };
              }

              return {
                positive: positive + amount,
                negative,
              };
            },
            {
              positive: 0,
              negative: 0,
            }
          );

          return positive - negative;
        },
      },
      {
        accountCode: "50.4.10",
        year: 0,
        operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
        calculate: (accountCodeAmountMap) => {
          const value = accountCodeAmountMap["50.4.10"];
          if (value < 0) {
            return "N/A";
          }

          return "";
        },
      },
    ],
    displayCode: "50.6.15",
    tp_standard_account_name: "Debt Service Coverage Ratio (x)",
    format: roundDecimal.bind(null, 2),
  },
];

export const FORMULAS_MAP = {
  [DOCUMENT_TYPE_ENUM.BALANCE_SHEET]: BALANCE_SHEET_SUBGROUP_FORMULA,
  [DOCUMENT_TYPE_ENUM.INCOME_STATEMENT]: INCOME_STATEMENT_SUBGROUP_FORMULA,
  [DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT]: CASH_FLOW_SUBGROUP_FORMULA,
  [DOCUMENT_TYPE_ENUM.CASH_FLOW_GENERATED]:
    CASH_FLOW_GENERATED_SUBGROUP_FORMULA,
  [DOCUMENT_TYPE_ENUM.KEY_RATIOS]: KEY_RATIOS_SUBGROUP_FORMULA,
};

export const BALANCE_SHEET_VALIDATION_FORMULA = {
  validation: {
    formula: {
      operations: [
        { accountCode: "20.3.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
        {
          accountCode: "20.6.00",
          operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        },
        {
          accountCode: "20.7.99",
          operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        },
      ],
      displayCode: "20.3.00",
    },
    equal: 0,
  },
  summary: [
    {
      operations: [
        { accountCode: "20.3.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      ],
      displayCode: "20.3.00",
      tp_standard_account_name: "Total Assets",
    },
    {
      operations: [
        { accountCode: "20.6.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      ],
      displayCode: "20.6.00",
      tp_standard_account_name: "Total Liabilities",
    },
    {
      operations: [
        { accountCode: "20.7.99", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      ],
      displayCode: "20.7.99",
      tp_standard_account_name: "Total Equity",
    },
    {
      operations: [
        { accountCode: "20.3.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
        {
          accountCode: "20.6.00,20.7.99",
          operation: FORMULA_CALCULATE_OPERATIONS.SUBSTRACT,
        },
        {
          accountCode: ACCOUNT_CODE_CONSTANT,
          operation: FORMULA_CALCULATE_OPERATIONS.PRINT,
          calculate: (_, totals, calculatedYear) => {
            const currentTotal = totals[calculatedYear];

            if (currentTotal === 0) {
              return "Balanced";
            }

            if (currentTotal > 0) {
              return "Assets are overstated by " + currentTotal;
            }

            return (
              "Liabilities and/or Equity are overstated by " + currentTotal
            );
          },
        },
      ],
      tp_standard_account_name: "",
    },
  ],
};

export const INCOME_STATEMENT_VALIDATION_FORMULA = {
  summary: [
    {
      operations: [
        { accountCode: "10.7.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      ],
      displayCode: "10.7.00",
      tp_standard_account_name: "Net Profit (Loss)",
    },
  ],
};

export const CASH_FLOW_VALIDATION_FORMULA = {
  summary: [
    {
      operations: [
        { accountCode: "30.7.00", operation: FORMULA_CALCULATE_OPERATIONS.ADD },
      ],
      displayCode: "30.7.00",
      tp_standard_account_name:
        "Net Increase / (Decrease) In Cash and Cash Equivalents",
    },
  ],
};

export const VALIDATION_FORMULA_MAP = {
  [DOCUMENT_TYPE_ENUM.BALANCE_SHEET]: BALANCE_SHEET_VALIDATION_FORMULA,
  [DOCUMENT_TYPE_ENUM.INCOME_STATEMENT]: INCOME_STATEMENT_VALIDATION_FORMULA,
  [DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT]: CASH_FLOW_VALIDATION_FORMULA,
};
