import React from 'react';
import Upload from '../../components/Upload';


const UploadAndProcessStep = ({ onFileSelect, file }) => {
  return (
    <div className="upload-step">
      <h2 className="text-xl font-semibold mb-4">Step 1: Upload & Process File</h2>
      <div>
        <p className="text-sm text-left font-semibold text-red-500 pb-6">Please upload the financial statements for analysis. Acceptable files: png <span className="font-small">(other files will be supported in the future)</span></p>
      </div>

      <Upload 
        onFileSelect={onFileSelect}
        file={file}
        accept={['image/*', 'application/pdf']}
      />
    </div>
  );
};

export default UploadAndProcessStep;