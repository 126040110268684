import { Fragment, useEffect, useRef, useState } from "react";
import downIcon from "../../assets/icons/icon_down.svg";
import savedIcon from "../../assets/icons/icon_saved.svg";
import upIcon from "../../assets/icons/icon_up.svg";
import usePendingApplications from "../../hooks/usePendingApplications";

export default function PendingApplications() {
  const { pendingApplications, hasPendingApplication } =
    usePendingApplications();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen((isOpen) => !isOpen);
  const pendingApplicationsDropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      pendingApplicationsDropdownRef.current &&
      !pendingApplicationsDropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!pendingApplications.length) {
    return null;
  }

  return (
    <div ref={pendingApplicationsDropdownRef} className="relative">
      <div
        className={`w-full border justify-evenly border-neutral-200 py-3 px-4 flex flex-row h-max items-center cursor-pointer rounded-2xl ${
          isOpen &&
          "border border-b-0 rounded-b-2xl rounded-bl-none rounded-br-none"
        }`}
        onClick={toggleDropdown}
      >
        {hasPendingApplication ? (
          <Fragment>
            <div className="animate-spin rounded-full h-[21.6px] w-[21.6px] border-[3px] border-primary-1 mr-4 border-t-white" />
            <span className="pr-3 text-primary-2 font-[700] text-[18px]">
              System Processing
            </span>
          </Fragment>
        ) : (
          <Fragment>
            <img src={savedIcon} className="mr-4 h-6" alt="Done" />
            <span className="pr-3">Mapping Completed</span>
          </Fragment>
        )}
        <img src={isOpen ? upIcon : downIcon} className="h-6" alt="Toggle" />
      </div>
      {isOpen && (
        <div className="w-full absolute top-full bg-white border border-t-0 rounded-b-2xl flex flex-col w-72s p-[16px]">
          {pendingApplications.map(({ companyName, id, status }) => {
            return (
              <button
                className="flex justify-between hover:bg-gray-100 border-b-[1px] py-[8px]"
                onClick={() =>
                  console.log("going to continue application id:", id)
                }
              >
                <span className="text-neutral-strong text-[16px] font-[600]">
                  {companyName}
                </span>
                {status === "IN_PROGRESS" && (
                  <div className="animate-spin rounded-full h-[21.6px] w-[21.6px] border-[3px] border-primary-1 border-t-white" />
                )}
                {status === "DONE" && (
                  <img src={savedIcon} className="h-6" alt="Done" />
                )}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}
