import React, { useEffect, useRef, useState } from "react";
import { DOCUMENT_TYPE_UPLOADED } from "../../constants";
import TableDisplayV2 from "./TableDisplayV2";
import Upload from "../../components/Upload";
import { VALIDATION_FORMULA_MAP } from "../../constants/formula";
import calculateFormulaFromData from "../../utils/formula/calculate_formula_from_data";
import Preview from "../../components/Preview";


const ValidateDataStepV2 = ({
  tpStandardAccountOptionsMap,
  data,
  metaData,
  setFinancialData,
  onUpload,
  calculatedData,
}) => {
  const [file, setFile] = useState(null);
  const viewerRef = useRef(null);
  const highlightsRef = useRef([]);
  const [selectedRow, setSelectedRow] = useState()
  const [aggregatedTextContents, setAggregatedTextContents] = useState([])
  const [isPageLoaded, setIsPageLoaded] = useState(false)

  const [activeTab, setActiveTab] = useState(
    () => Object.keys(metaData)[0] || DOCUMENT_TYPE_UPLOADED[0].key
  );

  const { summary = [] } = VALIDATION_FORMULA_MAP[activeTab] 

  const summaryData = calculateFormulaFromData(calculatedData, summary, {}, {
    document_type: activeTab
  })

  const tpStandardAccountOptions = tpStandardAccountOptionsMap[activeTab]

  const hasData = !!data.filter(
    (currentData) => currentData.document_type === activeTab
  ).length;

  const { unit, currency  } = metaData


  const { file: documentFile, url, page_number, fiscal_period_order } = metaData[activeTab] || {};

  const handleUpload = () => onUpload(file, activeTab).then(() => setFile(null));


  useEffect(() => {
    const selectedRef = highlightsRef.current[selectedRow];
    highlightsRef.current.forEach((ref) => {
      ref.style.display = "none";
    });

    if (!selectedRef) {
      return;
    }
    selectedRef.style.display = "";
  }, [selectedRow]);

  useEffect(() => {
    if (page_number && isPageLoaded) {
      const initialPageDiv = viewerRef.current.querySelectorAll('.rpv-core__text-layer')[page_number - 1]
      if (initialPageDiv) {
        initialPageDiv.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [page_number, isPageLoaded])

  const loadDataIntoPdf = (textContent, [, , width, height], currentPageIndex) => {
    setIsPageLoaded(true)
    const { items } = textContent;
    const result = [];
    let currentArray = [];
    let constructedStr = "";

    const currentPageDiv = viewerRef.current.querySelectorAll('.rpv-core__text-layer')[currentPageIndex]

    const textDivs = currentPageDiv.querySelectorAll(
      ".rpv-core__text-layer-text"
    );

    textDivs.forEach((element, index) => {
      const str = element.textContent
      const isNewLine = element.tagName === 'BR'
      if (isNewLine) {
        if (currentArray.length > 0) {
          result.push({
            constructedStr,
            content: currentArray,
            width,
            height,
          });
          currentArray = [];
          constructedStr = "";
        }
      } else {
        currentArray.push({ parentRef: textDivs[index] });
        constructedStr += str;
      }
    });

    setAggregatedTextContents(result)
  }

  const generateHighlightDiv = ({
    width,
    height,
  }) => {
    const highlightDiv = document.createElement("span");
    highlightDiv.style.position = "absolute";
    highlightDiv.className = 'border-2 border-solid border-blue-600 bg-blue-600/[.08]'
    highlightDiv.style.padding = `4px`;
    highlightDiv.style.left = `${-2}px`;
    highlightDiv.style.top = `${-4}px`;
    highlightDiv.style.width = `${width}px`;

    highlightDiv.style.height = `${height + 8}px`;
    highlightDiv.style.display = "none";

    return highlightDiv
  }

  useEffect(() => {
    if (!data.length || !aggregatedTextContents.length) {
      return;
    }

    highlightsRef.current.forEach((ref) => ref.remove());
    highlightsRef.current = [];
    setSelectedRow(null)
    let lastMatchedIndex = 0;

    data.forEach(({ client_account_name, document_type }, dataIndex) => {
      if (document_type !== activeTab) {
        return
      }
      for (let i = lastMatchedIndex; i < aggregatedTextContents.length; i++) {
        const { constructedStr, content } = aggregatedTextContents[i]

        const lowerClientAccountName = client_account_name.toLowerCase().trim()
        const lowerConstructedStr = constructedStr.toLowerCase().trim()

        if (lowerConstructedStr.startsWith(lowerClientAccountName)) {
          const { parentRef: firstParentElement } = content[0];
            const { parentRef: lastParentElement } = content[content.length - 1];
  
            const { left, height } = firstParentElement.getBoundingClientRect();
  
            const { right } = lastParentElement.getBoundingClientRect();
  
            const parentTransform =
              window.getComputedStyle(firstParentElement).transform;
  
            let scaleX = 1;
  
            if (parentTransform !== "none") {
              const matrixValues = parentTransform.match(/matrix\(([^)]+)\)/);
  
              if (matrixValues) {
                const values = matrixValues[1].split(",");
  
                scaleX = parseFloat(values[0]);
              }
            }

            const highlightDiv = generateHighlightDiv({
              width: ((right - left) / scaleX) + 4,
              height,
            })
  
            highlightsRef.current[dataIndex] = highlightDiv;
            firstParentElement.appendChild(highlightDiv);

            lastMatchedIndex = i;
            break
          }

        // multi line client account name
        if (lowerClientAccountName.startsWith(lowerConstructedStr)) {
          const { parentRef: firstParentElement } = content[0];
          const { parentRef: lastParentElement } = content[content.length - 1];
          const firstParentElements = [firstParentElement]
          const lastParentElements = [lastParentElement]
          let remainingCheckedStr = lowerClientAccountName.substring(lowerConstructedStr.length).trim();
          for (let j = i + 1; j < aggregatedTextContents.length; j++) {
            const { constructedStr, content } = aggregatedTextContents[j]
            const lowerConstructedStr = constructedStr.toLowerCase().trim()

            if (!(lowerConstructedStr.startsWith(remainingCheckedStr) || remainingCheckedStr.startsWith(lowerConstructedStr))) {
              i = j - 1
              break
            }

            const { parentRef: firstParentElement } = content[0];
          const { parentRef: lastParentElement } = content[content.length - 1];
          firstParentElements.push(firstParentElement)
          lastParentElements.push(lastParentElement)

            // last string
            if (lowerConstructedStr.startsWith(remainingCheckedStr)) {
              i = j
              break
            }
          }
          const { left, top } = firstParentElement.getBoundingClientRect();

          const { bottom: bottomFirstParentElementBottom } = firstParentElements[firstParentElements.length - 1].getBoundingClientRect()
          const height = bottomFirstParentElementBottom - top

          const right = lastParentElements.reduce((right, currentLastParentElement) => {
            const { right: currentRight } = currentLastParentElement.getBoundingClientRect();
            if (currentRight > right) {
              return currentRight
            }

            return right
          }, 0)

          const parentTransform =
            window.getComputedStyle(firstParentElement).transform;

          let scaleX = 1;

          if (parentTransform !== "none") {
            const matrixValues = parentTransform.match(/matrix\(([^)]+)\)/);

            if (matrixValues) {
              const values = matrixValues[1].split(",");

              scaleX = parseFloat(values[0]);
            }
          }

          const highlightDiv = generateHighlightDiv({
            width: ((right - left) / scaleX) + 4,
            height,
          })

          highlightsRef.current[dataIndex] = highlightDiv;
          firstParentElement.appendChild(highlightDiv);

          lastMatchedIndex = i;

          break
        }
      }
    })
  }, [aggregatedTextContents, data])

  return (
    <div className="validation-step">
      <h2 className="text-xl font-semibold mb-4">Step 2: Validate Data</h2>

      <div className="table-container mt-4 p-2 border border-gray-300 rounded-lg">
        <div className="mb-6">
          <h2 className="text-sm font-semibold text-red-500 dark:text-white">
            Instructions:
          </h2>
          <ol className="text-sm text-red-500 list-decimal list-inside">
            <li>
              Review Documents: Compare the original financial statements on the
              left with the mapped accounts and figures on the right.
            </li>
            <li>
              Verify and Edit: Confirm accuracy of mappings and figures. For
              corrections, click the edit icon in the Actions column, select the
              appropriate account or adjust numbers as needed.
            </li>
            <li>
              Confirmation: After verifying all details, click “Next” to proceed
            </li>
          </ol>
        </div>
        <div className="flex border-b mb-4">
          {DOCUMENT_TYPE_UPLOADED.map(({ key, text }, index) => (
            <button
              key={index}
              className={`py-2 px-4 focus:outline-none ${
                activeTab === key
                  ? "border-b-2 border-blue-500 font-semibold"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab(key)}
            >
              {text}
            </button>
          ))}
        </div>
      </div>
      <div className="mt-2">
        {!hasData ? (
          <div>
            <div className="flex justify-center">
              <button
                className={`next-button mt-2 py-2 px-4 rounded ${
                  !file
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-blue-500 text-white"
                }`}
                onClick={handleUpload}
                disabled={!file}
              >
                Upload
              </button>
            </div>
            <Upload
              onFileSelect={setFile}
              file={file}
              accept={['image/*', 'application/pdf']}
            />
          </div>
        ) : (
          <div className="flex h-screen">
            <div
              className="flex-1 overflow-y-scroll"
            >
              <h3 className="text-medium font-semibold mb-3">
                {" "}
                Original Image:{" "}
              </h3>
              {documentFile && (
                <div className="w-full" ref={viewerRef}><Preview file={documentFile} url={url} onPageLoad={loadDataIntoPdf} /></div>
              )}
            </div>

            {/* Display table */}
            <div className="flex-1 overflow-y-scroll">
              <h3 className="text-medium font-semibold mb-4">
                Mapped Financial Statement:
              </h3>
              {data ? (
                <TableDisplayV2
                  documentType={activeTab}
                  unit={unit}
                  currency={currency}
                  tpStandardAccountOptions={tpStandardAccountOptions}
                  editedData={data}
                  setEditedData={setFinancialData}
                  summary={summaryData}
                  onRowClick={setSelectedRow}
                  periodOrder={fiscal_period_order}
                />
              ) : (
                <p className="text-center text-gray-500">
                  No data to display. Please upload a file first.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ValidateDataStepV2;
