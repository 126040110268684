import { useState } from "react";
import backIcon from "../../assets/icons/icon_back.svg";
import Dropdowns from "../../components/Dropdowns";
import Popover from "../../components/Popover";
import Tabs from "../../components/Tabs";
import TextInput from "../../components/TextInput";

export default function EditCompanyFinancialSpreading() {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [buttonPosition, setButtonPosition] = useState(null);

  // Toggle the popover and get the button's position
  const handleButtonClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    setButtonPosition({
      top: rect.top,
      left: rect.left,
      height: rect.height,
    });
    setPopoverOpen(!popoverOpen);
  };

  // Function to close the popover
  const closePopover = () => {
    setPopoverOpen(false); // Close popover when clicking outside or triggering close
  };
  return (
    <div className="w-full max-w-default px-16 relative min-h-[calc(100vh-70px)] flex flex-col">
      <div className="py-[40px] flex gap-[16px]">
        <img src={backIcon} alt="back-button" className="cursor-pointer" />
        <span className="text-gradient-aurora-blue  text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
          Edit Company Financial Spreading
        </span>
      </div>

      {popoverOpen && buttonPosition && (
        <Popover position={buttonPosition} closePopover={closePopover} />
      )}
      <div className="flex flex-col items-center gap-[32px] w-full">
        <div className="flex flex-col p-[32px] gap-[24px] bg-white max-w-[1792px] w-full rounded-[20px]">
          <div className="flex gap-[24px] w-full">
            <TextInput label="Group Name" className="flex-1" />
            <TextInput label="Company Name" className="flex-1" />
          </div>
          <div className="flex gap-[24px] w-full">
            <Dropdowns
              label="Document Currency"
              data={[]}
              className={"flex-1"}
            />
            <Dropdowns label="Output Currency" data={[]} className={"flex-1"} />
          </div>
          <div className="flex gap-[24px] w-full">
            <TextInput label="Group Name" className="flex-1" />
            <Dropdowns label="Unit" data={[]} className={"flex-1"} />
          </div>
        </div>
        <div className="max-w-[1792px] w-full flex flex-col gap-[24px]">
          <Tabs />
          <div className="flex gap-[24px]">
            <div className="bg-white p-[24px] border-[1px] border-solid rounded-[20px] flex flex-col flex-1">
              PDF FILE
            </div>
            <div className="bg-white p-[24px] border-[1px] border-solid rounded-[20px] flex flex-col flex-1">
              <span className="leading-[28px] tracking-[-0.6px] font-[700] text-[20px] text-black">
                Financial Mapping
              </span>
              <table className="table-auto">
                <thead className="">
                  <tr className="border-b-[1px] border-b-card-on-surface">
                    <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tl-[8px]">
                      <div
                        className="flex items-center gap-[2px]"
                        onClick={handleButtonClick}
                      >
                        Client Account Name
                      </div>
                    </th>
                    <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50">
                      <div className="flex justify-end float-right w-full">
                        <span>Aurora Standard Account Name</span>
                      </div>
                    </th>
                    <th className="text-Gray-500 text-[12px] font-[600] leading-[16px] bg-primary-50 rounded-tr-[8px]">
                      <div className="flex justify-end">Mar 2022</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Cash and Cash Equivalents</td>
                    <td>
                      <div className="flex justify-end">$4255.00</div>
                    </td>
                    <td>
                      <div className="flex justify-end">$4255.00</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Restricted Cash and Cash Equivalents</td>
                    <td>
                      <div className="flex justify-end">$4255.00</div>
                    </td>
                    <td>
                      <div className="flex justify-end">$4255.00</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Short-Term Investments</td>
                    <td>
                      <div className="flex justify-end">$4255.00</div>
                    </td>
                    <td>
                      <div className="flex justify-end">$4255.00</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
