import { useEffect, useRef, useState } from "react";
import emailIcon from "../../assets/icons/icon_mail_blue.svg";
import calculateRemainingTime from "../../utils/time/calculate_remaining_time";

export default function ForgotPasswordResendEmail({
  email,
  expiryDate,
  onResend,
  onBackToLogin,
}) {
  const [{ difference, ...timeLeft }, setTimeLeft] = useState(
    calculateRemainingTime(expiryDate)
  );
  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft(calculateRemainingTime(expiryDate));
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [expiryDate]);

  const padNumber = (num) => String(num).padStart(2, "0");

  const formatTimeLeft = ({ days, hours, minutes, seconds }) => {
    return `${padNumber(days)}:${padNumber(hours)}:${padNumber(
      minutes
    )}:${padNumber(seconds)}`;
  };

  const isResendDisabled = difference > 0;

  return (
    <div className="flex flex-col items-center justify-center">
      <img src={emailIcon} alt="Email" />
      <div className="text-center pt-4 pb-4">
        <span className="text-aurora-blue check-your-email">
          Check your email!
        </span>
        <p className="pt-3 text-neutral-strong pb-11">
          We have sent you reset password email to <br />
          <span className="email-detail">{email}</span>, please check your inbox
        </p>
        <p className="didnt-recieve-email">
          Didn’t receive the email? You can request us to resend email in{" "}
          <span className="count-down">{formatTimeLeft(timeLeft)}</span>
        </p>
      </div>
      <button
        className={`mb-4 w-full ${!isResendDisabled && "primary"}`}
        onClick={() => !isResendDisabled && onResend()}
        disabled={isResendDisabled}
      >
        Resend Email
      </button>
      <button
        className="default w-full border border-neutral-400 bg-white"
        onClick={onBackToLogin}
      >
        Back to Login
      </button>
    </div>
  );
}
