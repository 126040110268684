export const mockData = [
  {
    id: 1,
    clientAccountName: "Cash and cash equivalents",
    standardAccountName: "Cash and cash equivalents",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 2,
    clientAccountName: "Restricted cash and cash equivalents",
    standardAccountName: "Restricted cash and cash equivalents",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 3,
    clientAccountName: "Short-term investments",
    standardAccountName: "Short-term investments",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 4,
    clientAccountName: "Accounts receivable, net of allowance of $46 and $40",
    standardAccountName: "Accounts receivable, net of allowance of $46 and $40",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 5,
    clientAccountName: "Income taxes receivable",
    standardAccountName: "Income taxes receivable",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 6,
    clientAccountName: "Prepaid expenses and other current assets",
    standardAccountName: "Prepaid expenses and other current assets",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 7,
    clientAccountName: "Property and equipment, net",
    standardAccountName: "Property and equipment, net",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 8,
    clientAccountName: "Operating lease right-of-use assets",
    standardAccountName: "Operating lease right-of-use assets",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 9,
    clientAccountName: "Long-term investments and other assets",
    standardAccountName: "Long-term investments and other assets",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 10,
    clientAccountName: "Deferred income taxes",
    standardAccountName: "Deferred income taxes",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 11,
    clientAccountName: "Intangible assets, net",
    standardAccountName: "Intangible assets, net",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 12,
    clientAccountName: "Goodwill",
    standardAccountName: "Goodwill",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
  {
    id: 13,
    clientAccountName: "Goodwill",
    standardAccountName: "Goodwill",
    mar2023: "$4225.00",
    mar2022: "$4225.00",
  },
];
