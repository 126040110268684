import { useState } from "react";
import { useNavigate } from "react-router-dom";
import backIcon from "../../assets/icons/icon_back.svg";
import Dropdowns from "../../components/Dropdowns";
import Preview from "../../components/Preview";
import TextInput from "../../components/TextInput";
import Upload from "../../components/Upload";
import { FILE_TYPE_PDF } from "../../constants/file";
import { URL_APPLICATION_VALIDATE, URL_DASHBOARD } from "../../constants/url";
import { useApplicationContext } from "../../context/Application";
import { useLoader } from "../../context/Loader";

// TODO: fetch from api?
const currencyOptions = [
  {
    value: "GBP",
    label: "Great Britain Pounds",
  },
  {
    value: "USD",
    label: "United States Dollar",
  },
  {
    value: "IDR",
    label: "Indonesian Rupiah",
  },
];

const FIELDS = {
  group_name: "group_name",
  company_name: "company_name",
  doc_currency: "doc_currency",
  out_currency: "out_currency",
  exchange_rate: "exchange_rate",
};

const REQUIRED_FIELDS = Object.values(FIELDS);

const initialFormValue = REQUIRED_FIELDS.reduce((formValue, fieldName) => {
  return {
    ...formValue,
    [fieldName]: "",
  };
}, {});

export default function FinancialSpreadingUploadPage() {
  const [file, setFile] = useState();

  const [formData, setFormData] = useState(() => initialFormValue);
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const goToDashboard = () => {
    navigate(URL_DASHBOARD);
  };
  const { handleUploadProcess, metadata } = useApplicationContext();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    handleUploadProcess(formData, file).finally(() => {
      setLoading(false);
      navigate(URL_APPLICATION_VALIDATE);
    });
  };

  return (
    <div
      className={`w-full max-w-default px-16 relative flex flex-col items-center`}
    >
      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col items-center"
      >
        <div className="flex items-center justify-between w-full">
          <div className="py-[40px] flex gap-[16px] items-center">
            <img
              src={backIcon}
              alt="back-button"
              className="cursor-pointer"
              onClick={goToDashboard}
            />
            <span className="text-gradient-aurora-blue  text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
              Create a New Company Account
            </span>
          </div>
          <button
            className="!h-[48px] !py-[12px] !px-[20px] primary w-[100px] text-[16px] font-[600] leading-[24px] tracking-[-0.48px] text-white"
            type="submit"
            // disabled={
            //   !file ||
            //   REQUIRED_FIELDS.some((fieldName) => !formData[fieldName])
            // }
          >
            Next
          </button>
        </div>

        <div className="max-w-[1186px] w-full flex flex-col gap-[32px]">
          <div className="flex items-center justify-between gap-[24px] p-[32px] bg-white rounded-[20px]">
            <TextInput
              id={FIELDS.group_name}
              label="Group Name"
              placeholder="Enter Group Name"
              className="flex-1"
              required
              value={formData[FIELDS.group_name]}
              onChange={handleChange}
            />
            <TextInput
              id={FIELDS.company_name}
              label="Company Name"
              placeholder="Enter Company Name"
              className="flex-1"
              required
              value={formData[FIELDS.company_name]}
              onChange={handleChange}
            />
          </div>
          <div className="flex items-center justify-between gap-[24px] p-[32px] bg-white rounded-[20px]">
            <Dropdowns
              id={FIELDS.doc_currency}
              label="Document Currency"
              placeholder="Select a Currency"
              data={currencyOptions}
              className="flex-1"
              required
              onChange={handleChange}
              value={formData[FIELDS.doc_currency]}
            />
            <Dropdowns
              id={FIELDS.out_currency}
              label="Outcome Currency"
              placeholder="Select an outcome Currency"
              data={currencyOptions}
              className="flex-1"
              required
              onChange={handleChange}
              value={formData[FIELDS.out_currency]}
            />
            <TextInput
              id={FIELDS.exchange_rate}
              label="FX Rate"
              className="flex-1"
              placeholder="Enter FX rate"
              required
              value={formData[FIELDS.exchange_rate]}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
      <div className="mt-[32px] flex flex-col gap-[8px] p-[32px] bg-white rounded-[20px] w-full max-w-[1186px]">
        <span className="leading-[20px] tracking-[-0.28px] font-[500] text-[14px]">
          Upload Financial Statement
        </span>
        <Upload
          onFileSelect={setFile}
          file={file}
          accept={[FILE_TYPE_PDF]}
          acceptLabel="1 PDF file only. Max size of 15MB"
        />
      </div>
      {file && (
        <div className="p-[32px] mt-[32px] bg-white rounded-[20px] w-full max-w-[1186px]">
          <Preview file={file ?? metadata.INCOME_STATEMENT} />
        </div>
      )}
    </div>
  );
}
