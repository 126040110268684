import PropTypes from "prop-types";
import errorIcon from "../../assets/images/error.svg";

TextInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};

export default function TextInput({
  label,
  className,
  errorMessage,
  ...props
}) {
  const { id, name = id } = props;
  return (
    <div className={className}>
      {label && (
        <label htmlFor={id} className="pb-[4px] text-neutral-strong">
          {label}
        </label>
      )}
      <input
        {...props}
        name={name}
        className="text-neutral-strong font-[600] text-[16px] leading-[24px] tracking-[-0.32px] placeholder:font-[400]"
        style={{
          borderBottom: errorMessage
            ? "1.5px solid var(--color-border-error-default)"
            : "none",
        }}
      />
      {errorMessage && (
        <div className="flex gap-[4px] pt-[4px] absolute">
          <img src={errorIcon} />
          <span
            style={{
              color: "var(--color-border-error-default)",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "16px",
            }}
          >
            {errorMessage}
          </span>
        </div>
      )}
    </div>
  );
}
