import PropTypes from "prop-types";
import "./index.css";

Loader.propTypes = {
  isLoading: PropTypes.bool,
};

export default function Loader({ isLoading }) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="fixed inset-0 w-full h-full bg-white bg-opacity-80 backdrop-blur-md flex flex-col justify-center items-center z-50 text-[rgb(32,58,164)] text-xl flex flex-col items-center">
      <div className="loader" />
    </div>
  );
}
