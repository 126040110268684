export default function calculateRemainingTime(targetDate) {
  const now = new Date();
  let difference = new Date(targetDate) - now;

  if (difference <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const timeUnits = [
    { unit: "days", value: 1000 * 60 * 60 * 24 },
    { unit: "hours", value: 1000 * 60 * 60 },
    { unit: "minutes", value: 1000 * 60 },
    { unit: "seconds", value: 1000 },
  ];

  return timeUnits.reduce(
    (acc, { unit, value }) => {
      acc[unit] = Math.floor(difference / value);
      difference %= value;
      return acc;
    },
    {
      difference,
    }
  );
}
