import { Link } from "react-router-dom";
import iconEdit from "../../assets/icons/edit.png";
import backIcon from "../../assets/icons/icon_back.svg";
import defaultAvt from "../../assets/images/3d_avatar_19.png";
import { URL_CHANGE_PASSWORD } from "../../constants/url";

export default function Profile() {
  return (
    <div className="w-full max-w-default px-16 relative min-h-[calc(100vh-70px)] flex flex-col">
      <div className="py-[40px] flex gap-[16px] w-full justify-between">
        <div className=" flex gap-[16px]">
          <img src={backIcon} alt="back-button" className="cursor-pointer" />
          <div>
            <span className="text-gradient-aurora-blue text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
              Profile Settings
            </span>
          </div>
        </div>
        <div className="self-end flex items-center gap-[20px]">
          {false && (
            <>
              <button className="font-[600] leading-[24px] tracking-[-0.48px] text-[16px] text-neutral-strong w-[91px] py-[12px] px-[20px] h-[48px] flex items-center rounded-[8px] border-solid border-neutral-medium border-[1px] flex items-center justify-center">
                Cancel
              </button>
              <button className="w-[91px] primary py-[12px] px-[20px] h-[48px] flex items-center justify-center">
                Save
              </button>
            </>
          )}
        </div>
      </div>
      <div className="w-full max-w-[884px] p-[24px] flex gap-[32px] bg-white rounded-[20px] self-center">
        <div className="relative w-[120x] h-[120px]">
          <img
            className="w-[120px] h-[120px] rounded-[50%]"
            src={defaultAvt}
            alt="avt"
          />
          <img
            className="w-[36px] h-[36px] p-[8px] rounded-[50%] absolute bottom-0 right-0 bg-edit-bg border-[2px] border-solid border-white"
            src={iconEdit}
            alt="edit"
          />
        </div>
        <div className="flex flex-col grow">
          <div className="flex flex-col justify-center gap-[12px] h-[128px] p-b-[8px] border-b-black-Alpha-200 border-b-[1px] border-solid">
            <span className="text-black text-[24px] font-[600] leading-[32px] tracking-[-0.72px]">
              Georgia Young
            </span>
            <div className="p-[8px] rounded-[4px] bg-display-blue-light flex items-center h-[24px] text-[14px] leading-[20px] tracking-[-0.28px] font-[500] w-fit text-display-blue-strong">
              Manager
            </div>
          </div>
          <div className="py-[32px] flex flex-col gap-[24px]">
            <div className="flex gap-[24px]">
              <span className="w-[80px] text-[16px] font-[500] leading-[24px] tracking-[-0.32px] text-Text-neutral-medium">
                Company
              </span>
              <span className="text-[18px] font-[600] leading-[24px] tracking-[-0.54px]">
                Company A
              </span>
            </div>
            <div className="flex gap-[24px]">
              <span className="w-[80px] text-[16px] font-[500] leading-[24px] tracking-[-0.32px] text-Text-neutral-medium">
                Team
              </span>
              <span className="text-[18px] font-[600] leading-[24px] tracking-[-0.54px]">
                Team A
              </span>
            </div>
            <div className="flex gap-[24px]">
              <span className="w-[80px] text-[16px] font-[500] leading-[24px] tracking-[-0.32px] text-Text-neutral-medium">
                Email
              </span>
              <span className="text-[18px] font-[600] leading-[24px] tracking-[-0.54px]">
                georgia.young@example.com
              </span>
            </div>
          </div>
          <Link to={URL_CHANGE_PASSWORD}>
            <button className="w-fit h-[40px] rounded-[8px] border-solid border-neutral-medium border-[1px] px-[16px]">
              Change Password
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
