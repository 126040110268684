import { useState } from "react";
import backIcon from "../../../assets/icons/icon_back.svg";
import hidePasswordIcon from "../../../assets/icons/icon_eye_closed.svg";
import showPasswordIcon from "../../../assets/icons/icon_eye_open.svg";
import TextInput from "../../../components/TextInput";

const FIELDS = {
  password: "newPassword",
  confirm_password: "confirmNewPassword",
};

const REQUIRED_FIELDS = Object.values(FIELDS);

const initialFormValue = REQUIRED_FIELDS.reduce((formValue, fieldName) => {
  return {
    ...formValue,
    [fieldName]: "",
  };
}, {});

export default function ProfileChangePassword() {
  const [formData, setFormData] = useState(initialFormValue);
  const isPasswordMatch = formData.newPassword === formData.confirmNewPassword;
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const togglePasswordVisibility = (type) => {
    setShowPassword((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };
  const isDisabledSubmitButton =
    REQUIRED_FIELDS.some((fieldName) => !formData[fieldName]) ||
    formData[FIELDS.confirm_password] !== formData[FIELDS.password];
  return (
    <div className="w-full max-w-default px-16 relative min-h-[calc(100vh-70px)] flex flex-col">
      <div className="py-[40px] flex gap-[16px]">
        <img src={backIcon} alt="back-button" className="cursor-pointer" />
        <span className="text-gradient-aurora-blue  text-[32px] font-[700] leading-[40px] tracking-[-0.96px]">
          Change Password
        </span>
      </div>
      <div className="w-full max-w-[582px] p-[44px] flex gap-[32px] bg-white rounded-[20px] self-center">
        <form onSubmit={() => {}} className="text-left w-full">
          <div className="relative">
            <TextInput
              name="currentPassword"
              id={FIELDS.password}
              label="Current Password"
              type={showPassword.currentPassword ? "text" : "password"}
              placeholder="Enter your current password"
              className="pb-6"
              onChange={handleChange}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <button
                type="button"
                onClick={() => {
                  togglePasswordVisibility("currentPassword");
                }}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {showPassword.currentPassword ? (
                  <img src={hidePasswordIcon} alt="hide password" />
                ) : (
                  <img src={showPasswordIcon} alt="show password" />
                )}
              </button>
            </div>
          </div>
          <div className="relative">
            <TextInput
              name="newPassword"
              id={FIELDS.password}
              label="New Password"
              type={showPassword.newPassword ? "text" : "password"}
              placeholder="Enter your new password"
              className="pb-6"
              onChange={handleChange}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <button
                type="button"
                onClick={() => {
                  togglePasswordVisibility("newPassword");
                }}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {showPassword.newPassword ? (
                  <img src={hidePasswordIcon} alt="hide password" />
                ) : (
                  <img src={showPasswordIcon} alt="show password" />
                )}
              </button>
            </div>
          </div>
          <div className="relative">
            <TextInput
              name="confirmNewPassword"
              id={FIELDS.confirm_password}
              label="Confirm New Password"
              type={showPassword.confirmNewPassword ? "text" : "password"}
              placeholder="Enter your new password"
              onChange={handleChange}
              className="pb-6"
              errorMessage={
                !isPasswordMatch && formData.confirmNewPassword
                  ? "Password does not match"
                  : null
              }
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <button
                type="button"
                onClick={() => {
                  togglePasswordVisibility("confirmNewPassword");
                }}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {showPassword.confirmNewPassword ? (
                  <img src={hidePasswordIcon} alt="hide password" />
                ) : (
                  <img src={showPasswordIcon} alt="show password" />
                )}
              </button>
            </div>
          </div>
          <div className="pt-[12px]">
            <button
              type="submit"
              className={`primary w-full text-white`}
              disabled={isDisabledSubmitButton}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
