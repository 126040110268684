export const DOCUMENT_TYPE_ENUM = {
  INCOME_STATEMENT: "INCOME_STATEMENT",
  BALANCE_SHEET: "BALANCE_SHEET",
  CASH_FLOW_STATEMENT: "CASH_FLOW_STATEMENT",
  CASH_FLOW_GENERATED: "CASH_FLOW_GENERATED",
  KEY_RATIOS: "KEY_RATIOS",
};

export const DOCUMENT_TYPE_UPLOADED = [
  { key: DOCUMENT_TYPE_ENUM.BALANCE_SHEET, type: "required", text: 'Balance Sheet' },
  { key: DOCUMENT_TYPE_ENUM.INCOME_STATEMENT, type: "required", text: 'Income Statement' },
  { key: DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT, text: 'Cash Flow Statement' },
];

export const DOCUMENT_TYPE_GENERATED = [
  {
    key: DOCUMENT_TYPE_ENUM.CASH_FLOW_STATEMENT,
    type: "generated",
    formula_type: DOCUMENT_TYPE_ENUM.CASH_FLOW_GENERATED,
    text: 'Cash Flow Statement (Generated)',
  },
  { key: DOCUMENT_TYPE_ENUM.KEY_RATIOS, type: "generated", text: 'Key Ratios' },
];

export const TOAST_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
}