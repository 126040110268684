import { useEffect, useRef, useState } from "react";
import iconDropDown from "../../assets/icons/arrow_drop_down.svg";

export default function Dropdowns({
  data,
  label,
  className = "",
  inputClassName = "",
  placeholder = "",
  defaultValue = "",
}) {
  const [dropDownData, setDropDownData] = useState(data);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleChangeFilter = (keyWords) => {
    setDropDownData(
      data.filter((item) =>
        item.label.toLowerCase().includes(keyWords.toLowerCase())
      )
    );
  };
  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <div
      className={`relative inline-block text-left ${className}`}
      ref={dropdownRef}
    >
      <label htmlFor="menu-button pb-[4px]">{label}</label>
      <div className="relative">
        <input
          ref={inputRef}
          id="menu-button"
          defaultValue={defaultValue}
          aria-expanded={open}
          aria-haspopup="true"
          onClick={() => setOpen((prev) => !prev)}
          placeholder={placeholder}
          onChange={(e) => handleChangeFilter(e.target.value)}
          className={`text-neutral-strong font-[600] text-[16px] leading-[24px] tracking-[-0.32px] placeholder:font-[400] ${inputClassName}`}
        />
        <img
          src={iconDropDown}
          alt="arrow"
          className={`absolute right-[12px] top-[50%] translate-y-[-50%] ${
            open ? "rotate-180" : ""
          }`}
        />
      </div>
      {open && (
        <div
          className="absolute w-full z-10 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="p-[8px] max-h-[200px] overflow-y-auto" role="none">
            {dropDownData.length === 0 && (
              <span
                className="block px-[12px] py-[8px] text-[14px] font-[500] leading-[20px] tracking-[-0.28px] "
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0"
              >
                Not found
              </span>
            )}
            {dropDownData.length > 0 &&
              dropDownData.map((item) => (
                <span
                  className="block px-[12px] py-[8px] text-[14px] font-[500] leading-[20px] tracking-[-0.28px] hover:bg-neutral-flat-hover"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                  key={item.label}
                  onClick={() => {
                    inputRef.current.value = item.label;
                    setOpen(false);
                  }}
                >
                  {item.label}
                </span>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
