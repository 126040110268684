import config from "../config";

export const API_BASE_URL = config.API_BASE_URL + "/api";
export const API_UPLOAD_FILE =
  API_BASE_URL + "/financial-spreading/process-file";
export const API_URL_STANDARD_ACCOUNTS = API_BASE_URL + "/standard-accounts";
export const API_LOGIN = API_BASE_URL + "/auth/jwt/login";
export const API_LOGOUT = API_BASE_URL + "/auth/jwt/logout";
export const API_FORGOT_PASSWORD = API_BASE_URL + "/forgot-password";
export const API_RESET_PASSWORD = API_BASE_URL + "/reset-password";
export const API_USER_ME = API_BASE_URL + "/users/me";

export const URL_DASHBOARD = "/";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_RESET_PASSWORD = "/reset-password";
export const URL_LOGIN = "/login";
export const URL_FINANCIAL_SPREADING = "/financial-spreading";
export const URL_USER_PROFILE = "/profile";
export const URL_CHANGE_PASSWORD = "/change-password";
export const URL_CONTACT_US = "/contact-us";
export const URL_CONTACT_US_ERROR = "/error/contact-us";
export const URL_ERROR_STATUS = "/error/:status";
export const MANAGE_ACCOUNTS = "/manage-accounts";
export const VIEW_CLIENT = "/view-client";
export const EDIT_COMPANY_FINANCIAL_SPREADING = "/company-financial";
export const URL_APPLICATION = "/application";
export const URL_APPLICATION_CREATE = URL_APPLICATION + "/create";
export const URL_APPLICATION_VALIDATE = URL_APPLICATION + "/validate";
export const URL_CLIENTS = "/clients";
const URL_CLIENTS_SUMMARY = "/summary";
export const URL_CLIENTS_SUMMARY_ACTIVITY = URL_CLIENTS_SUMMARY + "/activities";
export const URL_CLIENTS_SUMMARY_PORTFOLIO = URL_CLIENTS_SUMMARY + "/portfolio";
