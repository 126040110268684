import React from 'react';

const WizardSteps = ({ currentStep }) => {
  const steps = [
    { id: 1, label: 'Upload File' },
    { id: 2, label: 'Validate Data' },
    { id: 3, label: 'Result' },
  ];

  return (
    <div className="wizard-steps flex justify-between mb-6">
      {steps.map((step) => (
        <div key={step.id} className={`step flex-1 text-center ${currentStep === step.id ? 'font-bold' : ''}`}>
          <div className={`step-circle w-8 h-8 mx-auto rounded-full flex items-center justify-center 
            ${currentStep >= step.id ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}>
            {step.id}
          </div>
          <p className="step-label mt-2 text-sm">{step.label}</p>
        </div>
      ))}
    </div>
  );
};

export default WizardSteps;