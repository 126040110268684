import closeIcon from "../../assets/icons/icon_close.svg";
import errorIcon3d from "../../assets/icons/icon_error_3d.svg";
import successIcon3d from "../../assets/icons/icon_success_3d.svg";
import { TOAST_TYPE } from "../../constants";

export default function Toaster({
  message,
  type,
  title,
  onClose,
}) {
  const typeProperties = {
    [TOAST_TYPE.ERROR]: {
      className: "bg-gradient-to-r from-[#FFD7CF] to-[#FFF8F6]",
      icon: errorIcon3d,
    },
    [TOAST_TYPE.SUCCESS]: {
      className: "bg-gradient-to-r from-[#D5F6D4] to-[#EDFCEC]",
      icon: successIcon3d,
    },
  };

  const { className = "", icon } = typeProperties[type] || {};

  return (
    <div
      className={`max-w-sm p-4 z-50 fixed right-20 top-20 flex flex-row items-center rounded-xl ${className}`}
    >
      {icon && <div className="mr-4">
        <img src={icon} alt="Close" className="min-w-12 w-12" />
      </div>}
      <div className="pr-4">
        {title && <p className="font-semibold">{title}</p>}
        <p className="text-sm">{message}</p>
      </div>
      <button onClick={onClose}>
        <img src={closeIcon} alt="Close" className="min-w-10 w-10" />
      </button>
    </div>
  );
}
