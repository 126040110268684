import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { API_LOGIN, API_LOGOUT, API_USER_ME } from "../../constants/url";

const AuthContext = createContext({
    handleLogin: async (_username, _password) => { },
    isAuthenticated: false,
    handleLogout: async () => {},
    user: null,
    isLoading: false,
})

export const useAuthContext = () => useContext(AuthContext)

export default function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null)
    const [isLoading, setLoading] = useState(true)

    const clearUserData = () => {
        setUser(null)
        setIsAuthenticated(false)
    }

    const fetchUserData = async () => {
        return axios.get(API_USER_ME, {
            withCredentials: true,
        }).then(({ data: userData }) => {
            setUser(userData)
            setIsAuthenticated(true)
        }).catch(() => {
            clearUserData()
        })
    }

    useEffect(() => {
        setLoading(true);

        fetchUserData().finally(() => {
            setLoading(false)
        })
    }, [])
    
    const handleLogin = async (username, password) => {
        setLoading(true)

        const formData = new FormData()
        formData.append('username', username)
        formData.append('password', password)
        return axios.post(API_LOGIN, formData, {
            withCredentials: true,
        }).then(fetchUserData).finally(() => {
            setLoading(false)
        });
    }

    const handleLogout = async () => {
        setLoading(true)
        return axios.post(API_LOGOUT, {}, {
            withCredentials: true,
        }).catch(() => {
            // ignore error
        }).finally(() => {
            clearUserData()
            setLoading(false)
        })
    }

    return (
        <AuthContext.Provider value={{ handleLogin, isAuthenticated, handleLogout, user, isLoading }}>{children}</AuthContext.Provider>
    )

}